import { combineReducers } from 'redux';
import {
  CHANGE_AUTHENTICATION,
  SET_APPLICATION_INFORMATION_IS_LOADED,
  SET_CALENDAR_DATE,
  SET_CONTAINER_LOADER,
  SET_SELECTED_PACKAGE,
  SET_PACKAGES,
  SET_CURRENT_PRODUCT,
  SET_IS_CURRENCY_DOLLAR,
  SET_JWT,
  SET_PRIVILEGES,
  SET_PRIVILEGE_NAMES,
  SET_USER,
  SET_IS_LOGIN,
  LOG_OUT,
  SET_IS_SIDEBAR_OPEN, SET_SIDEBAR_WIDTH,
  SET_IS_MOBILE,
} from './types';

const initialState = {
  isAuthenticated: false,
  jwt: null,
  user: {},
  privileges: [],
  privilegeNames: {},
  applicationInformationIsLoaded: false,
  containerLoader: false,
  currentProduct: {},
  isLogin: false,
  calendarDate: { 'test': 'deneme' },
  isCurrencyDollar: false,
  sidebarWidth:338,
  isSidebarOpen:false,
  isMobile:false,
  packages: [{
    id:'standart',
    title: 'STANDART',
    monthlyAmount: 132.53,
    annualAmount: 1590.36,
    suffix: 'Kullanıcı / Yıl',
    benefit1: '1-10 Kullanıcı',
    benefit2: '*30 Gün Ücretsiz Deneme Sürümü',
    buyNow: true,
    bgColor: '#FBFBFB',
    isAdvantage: false,
    buttonText:'packetBuyNow',
    buttonUrl: '/buypackage',
    userCount:1,
    licenseType:'monthly',
    details:[
      {
        id:'userCount',
        value:1
      },
      {
        id:'maxUserCount',
        value:100
      },
      {
        id:'maxMeetingTime',
        value:'10s'
      },
      {
        id:'isCreateQuicklyMeeting',
      },
      {
        id:'isPlanMeeting',
      },
      {
        id:'meetingRecording',
      },
      {
        id:'maxStudyRoom',
        value:16
      },
      {
        id:'meetingNoteCreation',
      },
      {
        id:'customizedBackgrounds',
      },
      {
        id:'screenShare',
      },
      {
        id:'inChatMeeting',
      },
    ]
  }, {
    id:'business',
    title: 'BUSINESS',
    monthlyAmount: 110.75,
    annualAmount: 1329,
    suffix: 'Kullanıcı / Yıl',
    benefit1: '11-100 Kullanıcı',
    benefit2: '',
    buyNow: false,
    bgColor: '#F7F6FF',
    isAdvantage: true,
    buttonText:'packetBuyNow',
    buttonUrl: '/buypackage',
    userCount:11,
    licenseType:'monthly',
    details:[
      {
        id:'userCount',
        value:11
      },
      {
        id:'maxUserCount',
        value:300
      },
      {
        id:'maxMeetingTime',
        value:'unlimited'
      },
      {
        id:'isCreateQuicklyMeeting',
      },
      {
        id:'isPlanMeeting',
      },
      {
        id:'meetingRecording',
      },
      {
        id:'maxStudyRoom',
        value:16
      },
      {
        id:'meetingNoteCreation',
      },
      {
        id:'customizedBackgrounds',
      },
      {
        id:'screenShare',
      },
      {
        id:'inChatMeeting',
      },
    ]
  }, {
    id:'enterprise',
    title: 'ENTERPRISE',
    monthlyAmount: 'reachTheSalesUnit1',
    annualAmount: 'reachTheSalesUnit1',
    suffix: '',
    benefit1: '100+ Kullanıcı',
    benefit2: '',
    buyNow: false,
    bgColor: '#FBFBFB',
    isAdvantage: false,
    buttonText:'reachTheSalesUnit',
    buttonUrl: '/contact-us?type=enterprise',
    userCount:101,
    licenseType:'monthly',
    details:[
      {
        id:'userCount',
        value:'+100'
      },
      {
        id:'maxUserCount',
        value:500
      },
      {
        id:'maxMeetingTime',
        value:'10s'
      },
      {
        id:'isCreateQuicklyMeeting',
      },
      {
        id:'isPlanMeeting',
      },
      {
        id:'meetingRecording',
      },
      {
        id:'maxStudyRoom',
        value:16
      },
      {
        id:'meetingNoteCreation',
      },
      {
        id:'customizedBackgrounds',
      },
      {
        id:'screenShare',
      },
      {
        id:'inChatMeeting',
      },
    ]
  }]
};

const mainReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_AUTHENTICATION:
      return {
        ...state,
        isAuthenticated: action.payload
      };
    case SET_JWT:
      return {
        ...state,
        jwt: action.payload
      };
    case SET_USER:
      return {
        ...state,
        user: action.payload
      };
    case SET_PRIVILEGES:
      return {
        ...state,
        privileges: action.payload
      };
    case SET_APPLICATION_INFORMATION_IS_LOADED:
      return {
        ...state,
        applicationInformationIsLoaded: action.payload
      };
    // NEED TO IMPLEMENT THIS PROPERLY, IT DOES NOTHING ATM
    case SET_PRIVILEGE_NAMES:
      return {
        ...state
      };
    case SET_CONTAINER_LOADER:
      return {
        ...state,
        containerLoader: action.payload
      };
    case SET_CURRENT_PRODUCT:
      return {
        ...state,
        currentProduct: action.payload
      };
    case SET_SELECTED_PACKAGE:
      return {
        ...state,
        selectedPackage: action.payload
      };
    case SET_PACKAGES:
      return {
        ...state,
        packages: action.payload
      };
    case SET_IS_LOGIN:
      return {
        ...state,
        AAAA: action.payload
      };
    case SET_CALENDAR_DATE:
      return {
        ...state,
        calendarDate: action.payload
      };
    case SET_IS_CURRENCY_DOLLAR:
      return {
        ...state,
        isCurrencyDollar: action.payload
      };
    case LOG_OUT:
      return initialState;
    case SET_IS_SIDEBAR_OPEN:
      return {
        ...state,
        isSidebarOpen: action.payload
      };
    case SET_SIDEBAR_WIDTH:
      return  {
        ...state,
        sidebarWidth: action.payload
      };
    case SET_IS_MOBILE:
      return  {
        ...state,
        isMobile: action.payload
      };
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  main: mainReducer,
});

export default rootReducer;
