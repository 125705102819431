export const clean = (obj) => {
  if (obj){
    const propNames = Object.getOwnPropertyNames(obj);
    for (let i = 0; i < propNames.length; i++) {
      const propName = propNames[i];
      if (obj[propName] === '' || obj[propName] === null || obj[propName] === undefined) {
        delete obj[propName];
      }
    }
  }
};
export default {
  clean
};
