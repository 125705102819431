import React from 'react';
import { Grid, Box, Typography, Card, CardContent } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  onlineUsers,
  activeMeetings,
  totalMeetings,
  pastMeetings,
  topUsers,
  topPairs,
  meetingStats
} from './dummyData';

function Dashboard() {
  const { t } = useTranslation();

  return (
    <Grid container spacing={3} padding={3}>
      {/* Dashboard Başlık */}
      <Grid item xs={12}>
        <Typography variant="h4" sx={{ fontWeight: 600, color: '#4a4b4b', mb: 2 }}>
          {t('dashboard')}
        </Typography>
      </Grid>

      {/* KPI Kartları */}
      <Grid item xs={12} md={4}>
        <Card>
          <CardContent>
            <Typography variant="h6">{t('onlineUsers')}</Typography>
            <Typography variant="h4" color="primary">{onlineUsers}</Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={4}>
        <Card>
          <CardContent>
            <Typography variant="h6">{t('activeMeetings')}</Typography>
            <Typography variant="h4" color="secondary">{activeMeetings}</Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={4}>
        <Card>
          <CardContent>
            <Typography variant="h6">{t('totalMeetings')}</Typography>
            <Typography variant="h4" color="success">{totalMeetings}</Typography>
          </CardContent>
        </Card>
      </Grid>

      {/* Toplantı İstatistikleri */}
      <Grid item xs={12}>
        <Typography variant="h6" sx={{ mb: 2 }}>{t('meetingStats')}</Typography>
        <Grid container spacing={3}>
          {Object.keys(meetingStats).map((key) => (
            <Grid item xs={12} sm={6} md={3} key={key}>
              <Card>
                <CardContent>
                  <Typography variant="subtitle1">{t(key)}</Typography>
                  <Typography variant="h5">{meetingStats[key].count} {t('dashboardMeetings')}</Typography>
                  <Typography variant="body2" color="textSecondary">
                    {t('totalDuration')}: {meetingStats[key].duration} {t('minutes')}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Grid>

      {/* Geçmiş Toplantılar Tablosu */}
      <Grid item xs={12}>
        <Typography variant="h6" sx={{ mb: 2 }}>{t('pastMeetings')}</Typography>
        <Box>
          <table style={{ width: '100%', borderCollapse: 'collapse' }}>
            <thead>
              <tr style={{ borderBottom: '2px solid #ddd' }}>
                <th style={{ padding: '8px' }}>{t('title')}</th>
                <th style={{ padding: '8px' }}>{t('duration')}</th>
                <th style={{ padding: '8px' }}>{t('date')}</th>
              </tr>
            </thead>
            <tbody>
              {pastMeetings.map((meeting) => (
                <tr key={meeting.id}>
                  <td style={{ padding: '8px' }}>{meeting.title}</td>
                  <td style={{ padding: '8px' }}>{meeting.duration}</td>
                  <td style={{ padding: '8px' }}>{meeting.date}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Box>
      </Grid>

      {/* En Çok Toplantı Yapan Kullanıcılar */}
      <Grid item xs={12} md={6}>
        <Typography variant="h6" sx={{ mb: 2 }}>{t('topUsers')}</Typography>
        <Box>
          <ul>
            {topUsers.map((user, index) => (
              <li key={index}>
                {user.name}: {user.meetings} {t('meetings')}
              </li>
            ))}
          </ul>
        </Box>
      </Grid>

      {/* En Çok Birbiriyle Toplantı Yapan Çiftler */}
      <Grid item xs={12} md={6}>
        <Typography variant="h6" sx={{ mb: 2 }}>{t('topPairs')}</Typography>
        <Box>
          <ul>
            {topPairs.map((pair, index) => (
              <li key={index}>
                {pair.pair}: {pair.meetings} {t('dashboardMeetings')}
              </li>
            ))}
          </ul>
        </Box>
      </Grid>
    </Grid>
  );
}

export default Dashboard;
