import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import {Button, FormControl} from '@mui/material';
import Swal from 'sweetalert2';
import {useTranslation} from 'react-i18next';
import {useRef, useState} from 'react';
import {CompanyAdminServices} from '../../../../services';
import {Formik} from 'formik';
import {JSEncrypt} from 'jsencrypt';
import * as yup from 'yup';
import {LoadingButton} from '@mui/lab';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '0px solid #000',
  borderRadius: 2,
  boxShadow: 24,
  p: 4
};

function ChangePasswordModalSingle({setShowEditUserModal, showEditUserModal,selected, setSelected}) {
  const {t} = useTranslation();
  const formikRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [isMailSend, setIsMailSend] = React.useState(false);
  const validationSchema = yup.object({
    newPassword: yup
      .string()
      .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/, t('validPasswordRegex'))
      .required(t('validPassword'))
      .min(8, t('validPasswordMin')),
    repeatNewPassword: yup.string()
      .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/, t('validPasswordRegex'))
      .required(t('validPassword'))
      .oneOf([yup.ref('newPassword'), null], t('registerCheckPassword')),
  });

  const initialValues = {
    newPassword: '',
    repeatNewPassword:'',
  };

  const onSubmit = async (values) => {
    var encrypt = new JSEncrypt();
    setIsLoading(true);
    encrypt.setPublicKey('MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDGy8btrbnSNPz7vWKfQXKxKXzg28ZD8jCAd7gGYfUIFqKqUcogHWt5gyGvTgEhwBwBP1kYrVnBlhB2nuWHLYpJDI6buBoqKrHtrcdgXsKumSP0OKpn0nbYxknOvNYVjUUR6plMboUBaWX1oKoR6pNzTEHSal4bIU7XMwppkR3KNQIDAQAB');
    let sendValues = {emailUsers:[{email:selected && selected[0].email,password:encrypt.encrypt(values.newPassword)}],isMailSend};
    CompanyAdminServices.passwordResetUsers(sendValues).then((response)=>{
      setIsLoading(false);
      if (response){
        setShowEditUserModal(false);
        setSelected([]);
        Swal.fire({
          width: 400,
          title: t('success'),
          text: t('userIsPasswordChanged'),
          icon: 'success',
          confirmButtonText: t('ok')
        });
      }
    });
  };

  return (
    <div>
      <Modal
        open={showEditUserModal}
        onClose={() => setShowEditUserModal(false)}
      >
        <Box sx={style}>
          <Box
            display="flex"
            flexDirection={'row'}
            alignContent={'center'}
            justifyContent={'end'}
          >
            <Button onClick={() => setShowEditUserModal(false)}><CloseRoundedIcon/></Button>
          </Box>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{textAlign: 'center', fontWeight: 600, mb: 2, mt: -2}}>
            {t('passwordReset')}
          </Typography>

          <Formik innerRef={formikRef} initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnChange={true}
            validateOnBlur={true}
            onSubmit={values => {
              onSubmit(values);
            }}>
            {({ values, handleSubmit, errors, touched, handleChange }) => (
              <form onSubmit={handleSubmit}>
                {/*{user && selected[0].id === user.id &&   <FormControl fullWidth sx={{ mb: 3 }}>*/}
                {/*  <InputLabel variant="standard" htmlFor="uncontrolled-native">*/}
                {/*      Mevcut Şifre*/}
                {/*  </InputLabel>*/}
                {/*  <TextField label={'Mevcut Şifre'}*/}
                {/*    type={'password'}*/}
                {/*    value={values.currentPassword}*/}
                {/*    name={'currentPassword'}*/}
                {/*    size="small"*/}
                {/*    autoComplete={'false'}*/}
                {/*    fullWidth*/}
                {/*    variant="outlined"*/}
                {/*    onChange={handleChange}*/}
                {/*    error={touched.currentPassword && Boolean(errors.currentPassword)}*/}
                {/*    helperText={touched.currentPassword && errors.currentPassword}/>*/}
                {/*</FormControl>*/}
                {/*}*/}

                <FormControl fullWidth sx={{ mb: 3 }}>
                  <InputLabel variant="standard" htmlFor="uncontrolled-native">
                    {t('accountNewPassword')}
                  </InputLabel>
                  <TextField name={'newPassword'}
                    value={values.newPassword}
                    autoComplete={'false'}
                    type={'password'}
                    size={'small'}
                    id="outlined-basic"
                    label={t('accountNewPassword')}
                    variant="outlined"
                    onChange={handleChange}
                    error={touched.newPassword && Boolean(errors.newPassword)}
                    helperText={touched.newPassword && errors.newPassword}/>
                </FormControl>


                <FormControl fullWidth sx={{ mb: 3 }}>
                  <InputLabel variant="standard" htmlFor="uncontrolled-native">
                    {t('accountRepassword')}
                  </InputLabel>
                  <TextField name={'repeatNewPassword'}
                    value={values.repeatNewPassword}
                    autoComplete={'false'}
                    type={'password'}
                    size={'small'}
                    id="outlined-basic"
                    label={t('accountRepassword')}
                    variant="outlined"
                    onChange={handleChange}
                    error={touched.repeatNewPassword && Boolean(errors.repeatNewPassword)}
                    helperText={touched.repeatNewPassword && errors.repeatNewPassword}/>
                </FormControl>

                <FormGroup style={{marginBottom:30}}>
                  <FormControlLabel
                    disabled control={<Checkbox checked/>}
                    label={t('changePasswordFirstLogin')}
                  />
                  <FormControlLabel style={{marginTop:15}}
                    control={<Checkbox  checked={isMailSend} onChange={()=>setIsMailSend(!isMailSend)}/>}
                    label={t('sendEmail')}
                  />
                </FormGroup>

                <LoadingButton
                  loading={isLoading}
                  color={'primary'}
                  size={'large'}
                  variant={'contained'}
                  type={'submit'}
                  sx={{fontWeight: 500}}
                >
                  {t('save')}
                </LoadingButton>
              </form>
            )}
          </Formik>
        </Box>
      </Modal>
    </div>
  );
}

ChangePasswordModalSingle.propTypes = {
  selected: PropTypes.array,
  setSelected: PropTypes.func,
  showEditUserModal: PropTypes.bool,
  setShowEditUserModal: PropTypes.func,
  loginUserName: PropTypes.string
};

export default ChangePasswordModalSingle;
