// dummyData.js
export const onlineUsers = 32;
export const activeMeetings = 5;
export const totalMeetings = 120;

export const pastMeetings = [
  { id: 1, title: 'Team Sync', duration: '45 min', date: '2024-12-10' },
  { id: 2, title: 'Project Update', duration: '1 hr', date: '2024-12-09' },
  { id: 3, title: 'Client Call', duration: '30 min', date: '2024-12-08' },
];

export const topUsers = [
  { name: 'John Doe', meetings: 25 },
  { name: 'Jane Smith', meetings: 20 },
  { name: 'Emily Davis', meetings: 18 },
];

export const topPairs = [
  { pair: 'John Doe - Jane Smith', meetings: 12 },
  { pair: 'Emily Davis - John Doe', meetings: 10 },
  { pair: 'Jane Smith - Emily Davis', meetings: 8 },
];

export const meetingStats = {
  packetsDaily: { count: 10, duration: 120 },
  packetsWeekly: { count: 50, duration: 600 },
  packetsMonthly: { count: 200, duration: 2400 },
  packetsYearly: { count: 2400, duration: 28800 },
};