import { Ajax } from '../utils/index';

export default {
  login(data) {
    let promise = Ajax.postPromise('/Account/login', data);
    return promise;
  },
  publicLogin(data) {
    let promise = Ajax.postPublicPromise('/Account/login', data);
    return promise;
  },
  resetPassword(data) {
    let promise = Ajax.postPromise('/CompanyAdmin/password-forgot', data);
    return promise;
  },
  changePassword(data) {
    let promise = Ajax.postPromise('/CompanyAdmin/change-password-forgetten', data);
    return promise;
  },
  verifyAccount(data){
    let promise = Ajax.getPromise(data);
    return promise;
  },
  sendContactUs(data) {
    let promise = Ajax.postPublicPromise('/Account/contact-us', data);
    return promise;
  },
};
