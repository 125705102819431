import React, {useEffect} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from 'common/Container';
import {Link} from 'react-router-dom';
import LogoS from 'svg/logos/LogoBeyaz.png';
import LoginForm from './components/LoginForm';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import LanguageIcon from '@mui/icons-material/Language';
import {useTranslation} from 'react-i18next';

const LoginSimple = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [language, setLanguage] = React.useState('Türkçe');

  const handleClose = () => {
    setAnchorEl(null);
  };
  const {i18n} = useTranslation();
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const [screenSize, getDimension] = React.useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight
  });
  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight
    });
  };
  useEffect(() => {
    window.addEventListener('resize', setDimension);

    return (() => {
      window.removeEventListener('resize', setDimension);
    });
  }, [screenSize]);

  useEffect(() => {
    setLanguage(i18n.language == 'tr' ? 'Türkçe' : 'English');
  }, [i18n.language]);

  function changeLanguage(lang) {
    switch (lang) {
      case 'Türkçe':
        i18n.changeLanguage('tr').then(() => null);
        localStorage.setItem('i18nextLng', 'tr');
        break;
      case 'English':
        i18n.changeLanguage('en').then(() => null);
        localStorage.setItem('i18nextLng', 'en');
        break;
      default:
        i18n.changeLanguage('tr').then(() => null);
        localStorage.setItem('i18nextLng', 'tr');

    }
    setLanguage(lang);
  }

  return (
    <center>
      <div style={{height: '99vh'}}>
        <Box
          alignItems={'center'}
          justifyContent={'center'}
          xs={12}
          md={4}
          height={730}
          backgroundColor="#F6F6F6"
          sx={{
            borderRadius: 6,
            width: screenSize.dynamicWidth < 600 ? 350 : 450,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            webkitBoxShadow: '0px 0px 6px -3px rgba(0,0,0,0.75)',
            mozBoxShadow: '0px 0px 6px -3px rgba(0,0,0,0.75)',
            boxShadow: '0px 0px 6px -3px rgba(0,0,0,0.75)'
          }}
        >

          <Button
            startIcon={<LanguageIcon/>}
            sx={{width: 100, float: 'right', marginTop: 1, marginRight: 1, borderRadius: 5}}
            aria-describedby={id}
            variant="text"
            onClick={(event) => setAnchorEl(event.currentTarget)}
          >
            {language}
          </Button>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
          >
            <Typography
              sx={{p: 2, cursor: 'pointer'}}
              onClick={() => changeLanguage(language == 'English' ? 'Türkçe' : 'English')}
            >
              {language == 'English' ? 'Türkçe' : 'English'}
            </Typography>
          </Popover>

          <Container
            maxWidth={{sm: '100%', md: '70%'}}
            sx={{marginTop: 11}}
          >
            <Box>
              <Box
                to="/"
                component={Link}
                display={'flex'}
                justifyContent={'center'}
                marginBottom={3}
                marginLeft={'auto'}
                marginRight={'auto'}
                marginTop={{xs: 10, md: 0}}
                sx={{height: {md: '59px', xs: '40px'}, width: {md: '188px', xs: '170px'}}}>
                <img style={{maxWidth: '188px'}} src={LogoS}/>
              </Box>
            </Box>


            <LoginForm
              setShowModal={() => null}
            />
          </Container>
        </Box>
      </div>
    </center>

  );
};

export default LoginSimple;
