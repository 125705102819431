import { Ajax } from '../utils/index';

export default {
  getUserInformation () {
    let promise = Ajax.getPromise('/Session/get-current-user-information');
    return promise;
  },
  changePassword (data) {
    let promise = Ajax.postPromise('/user/change-password', data);
    return promise;
  }
};
