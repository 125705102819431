/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import {useFormik} from 'formik';
import * as yup from 'yup';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import {useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  borderRadius: 2,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 8,
};

const NewPasswordModal = ({showNewPasswordModal, setShowNewPasswordModal}) => {
  const {t} = useTranslation();
  const history = useHistory();
  const initialValues = {
    newPassword: '',
    repeatNewPassword: '',
  };

  const validationSchema = yup.object({
    newPassword: yup
      .string()
      .required(t('validPassword'))
      .min(8, t('validPasswordMin')),
    repeatNewPassword: yup.string().required(t('validPassword'))
      .oneOf([yup.ref('newPassword'), null], t('registerCheckPassword')),
  });

  const onSubmit = (values) => {
    resetPassword(values);
    return values;
  };

  const resetPassword = () => {
    history.push('/admin-main');
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  return (
    <Modal
      open={showNewPasswordModal}
      onClose={() => setShowNewPasswordModal(!showNewPasswordModal)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box sx={{position: 'absolute', top: 30, right: 30}}>
          <IconButton onClick={() => setShowNewPasswordModal(false)}>
            <CloseIcon/>
          </IconButton>
        </Box>
        <Box>
          <Box marginBottom={4} textAlign={'center'}>
            <Typography
              variant="h5"
              color="text.tertiary"
              sx={{
                fontWeight: 700,
              }}
            >
              {t('updatePassword')}
            </Typography>
            <Typography color="text.tertiary" marginTop={5} marginBottom={5}>
              {t('firstLoginChangePasswordMessage')}
            </Typography>
          </Box>
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  autoComplete={'false'}
                  label={t('newPassword')}
                  variant="outlined"
                  name={'newPassword'}
                  type={'password'}
                  fullWidth
                  value={formik.values.newPassword}
                  onChange={formik.handleChange}
                  error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
                  helperText={formik.touched.newPassword && formik.errors.newPassword}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  autoComplete={'false'}
                  label={t('newPasswordRepeat')}
                  variant="outlined"
                  name={'repeatNewPassword'}
                  type={'password'}
                  fullWidth
                  value={formik.values.repeatNewPassword}
                  onChange={formik.handleChange}
                  error={formik.touched.repeatNewPassword && Boolean(formik.errors.repeatNewPassword)}
                  helperText={formik.touched.repeatNewPassword && formik.errors.repeatNewPassword}
                />
              </Grid>
              <Grid item container xs={12}>
                <Box
                  display="flex"
                  flexDirection={{xs: 'column', sm: 'row'}}
                  alignItems={{xs: 'stretched', sm: 'center'}}
                  justifyContent={'center'}
                  width={'100%'}
                  sx={{marginTop: 5}}
                  maxWidth={600}
                  margin={'0 auto'}
                >
                  <Button size={'large'} variant={'contained'} type={'submit'} sx={{padding: '10px 50px'}}>
                    {t('accountSignIn')}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Box>
    </Modal>
  );
};

NewPasswordModal.propTypes = {
  showNewPasswordModal: PropTypes.bool,
  setShowNewPasswordModal: PropTypes.func
};

export default NewPasswordModal;
