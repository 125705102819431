/* eslint-disable react/no-unescaped-entities */
import React, {useState} from 'react';
import {useFormik} from 'formik';
import * as yup from 'yup';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {SvgIcon, Link} from '@mui/material';
import {AccountServices} from 'services/index';
import {ReactComponent as SuccessBadge} from 'svg/misc/successBadge.svg';
import {LoadingButton} from '@mui/lab';
import {useTranslation} from 'react-i18next';

const Form = () => {
  const {t} = useTranslation();
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const initialValues = {
    email: '',
  };

  const validationSchema = yup.object({
    email: yup
      .string(t('validEmail'))
      .trim()
      .email(t('validEmail'))
      .required(t('requiredField')),
  });

  const onSubmit = (values) => {
    setIsLoading(true);
    resetPassword(values);
    return values;
  };

  const resetPassword = (data) => {
    var promise = AccountServices.resetPassword(data);
    promise
      .then((response) => {
        setIsLoading(false);
        if (!response) return;
        setSuccess(true);
        //push route change
      })
      .catch((response) => {
        console.log(response);
        setIsLoading(false);
      });
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  // some basic conditional rendering in case of a success
  return success ?
    (<Box textAlign={'center'}>
      <Box display={'flex'} justifyContent={'center'} marginBottom={10}>
        <SvgIcon htmlColor={'#4A4B4B'} sx={{transform: 'scale(6)'}} component={SuccessBadge} viewBox='0 0 156 156'/>
      </Box>
      <Typography
        variant="h4"
        color="text.tertiary"
        sx={{
          fontWeight: 700,
        }}
      >
        {t('success')}
      </Typography>
      <Typography color="text.tertiary" marginY={2}>
        {t('pleaseClickEmail')}
      </Typography>
      <Button
        component={Link}
        color={'primary'}
        size={'large'}
        variant={'contained'}
        href='/page-login-simple'
        underline={'none'}

      >
        {t('confirmButtonText')}
      </Button>
    </Box>)
    : (
      <Box>
        <Box marginBottom={4} textAlign={'center'}>
          <Typography
            variant="h4"
            color="text.tertiary"
            sx={{
              fontWeight: 700,
            }}
          >
            {t('passwordReset')}
          </Typography>
          <Typography color="text.tertiary" marginTop={2}>
            {t('passwordResetEmailMessage')}
          </Typography>
        </Box>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label={t('email')}
                variant="outlined"
                name={'email'}
                fullWidth
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Grid>
            <Grid item container xs={12}>
              <Box
                display="flex"
                flexDirection={{xs: 'column', sm: 'row'}}
                alignItems={{xs: 'stretched', sm: 'center'}}
                justifyContent={'center'}
                width={'100%'}
                maxWidth={600}
                margin={'0 auto'}
              >

                <LoadingButton
                  loading={isLoading}
                  size={'large'}
                  variant={'contained'}
                  type={'submit'}
                  sx={{padding: '10px 50px'}}
                >
                  {t('send')}
                </LoadingButton>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Box>
    );
};

export default Form;
