import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

var ApplicationType = require('./../enums/applicationType');

var axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_ADMIN_API,
  json: true,
});

var publicAxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_PUBLIC_API,
  json: true,
});

axiosInstance.interceptors.request.use((config) => {
  var lang = window.localStorage.i18nextLng ?? 'tr';
  if (!window.sessionStorage.getItem('Correlation')) {
    window.sessionStorage.setItem('Correlation', uuidv4());
  }
  config.headers['X-CORRELATION-ID'] = window.sessionStorage.getItem('Correlation');
  config.headers['ApplicationType'] = ApplicationType.default.Web;
  config.headers['client-lang'] = lang;
  config.headers['ApplicationInformation'] = JSON.stringify({ 'ApplicationVersion': process.env.REACT_APP_VERSION });
  return config;
});

publicAxiosInstance.interceptors.request.use((config) => {
  var lang = window.localStorage.i18nextLng ?? 'tr';
  if (!window.sessionStorage.getItem('Correlation')) {
    window.sessionStorage.setItem('Correlation', uuidv4());
  }
  config.headers['X-CORRELATION-ID'] = window.sessionStorage.getItem('Correlation');
  config.headers['ApplicationType'] = ApplicationType.default.Web;
  config.headers['client-lang'] = lang;
  config.headers['ApplicationInformation'] = JSON.stringify({ 'ApplicationVersion': process.env.REACT_APP_VERSION });
  return config;
});

export default { axiosInstance, publicAxiosInstance };
