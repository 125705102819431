import React from 'react';
import DataTable from './components/DataTable';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import {useTranslation} from 'react-i18next';

function Products () {
  const {t} = useTranslation();

  return (<Grid
    item
    xs={12}
    sm={8}
    md={9}
    xl={10} paddingTop={window.innerWidth > 600 ? 15 : 10}
    paddingLeft={window.innerWidth > 1180 ? 15 : 3}
    paddingRight={window.innerWidth > 1180 ? 15 : 3}>
    <Typography
      variant="h4"
      sx={{fontWeight: 600, color: '#4a4b4b', mb: 5}}>
      {t('yourProducts')}
    </Typography>
    <Typography color="black" mb={5}>
      {t('packageText')}
    </Typography>
    <DataTable/>
  </Grid>);
}

export default Products;
