import {Checkbox, FormControlLabel} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import Link from '@mui/material/Link';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import {ErrorMessage, Formik} from 'formik';
import React, {useEffect, useRef} from 'react';
import * as yup from 'yup';
import 'yup-phone';
import {il_ilce} from './il-ilce';
import {ulkeler} from './ulkeler';
import PropTypes from 'prop-types';
import InfoIcon from '@mui/icons-material/Info';
import PhoneInput from 'react-phone-input-2';
import {useSelector} from 'react-redux';
import 'react-phone-input-2/lib/semantic-ui.css';
import {useTranslation} from 'react-i18next';

const InvoiceForm = ({type, onSubmited}) => {
  const formikRef = useRef();
  const {t} = useTranslation();
  const initialValues = {
    identityNumber: '',
    name: '',
    surname: '',
    companyTitle: '',
    billingAddress: '',
    taxAdministration: '',
    taxNumber: '',
    country: '',
    city: '',
    district: '',
    email: '',
    phoneNumber: '',
    licenceCount: '1',
    paymentContractTexts: {
      distanceSalesContractText: '',
      preliminaryInformationFormText: '',
      withdrawalRightText: ''
    },
    addressDescription: '',
    identityNumberType: '0',
    terms: false,
    showIdentityNumber: false,
  };
  const validationSchema = yup.object({
    identityNumberType: yup
      .string()
      .required(t('validIdentityNumberType')),
    email: yup
      .string()
      .trim()
      .email(t('validEmail'))
      .required(t('requiredField')),
    name: yup
      .string()
      .when('identityNumberType', {
        is: value => value && value === '0',
        then: yup.string().matches(/^'?(?:\p{L}\p{M}*)+(?:['\s](?:\p{L}\p{M}*)+)*'?$/u, t('validName')).min(2,t('validName')).max(40,t('validName')).required(t('requiredField'))
      }),
    surname: yup
      .string()
      .when('identityNumberType', {
        is: value => value && value === '0',
        then: yup.string().matches(/^'?(?:\p{L}\p{M}*)+(?:['\s](?:\p{L}\p{M}*)+)*'?$/u, t('validSurname')).min(2,t('validSurname')).max(40,t('validSurname')).required(t('requiredField'))
      }),
    companyTitle: yup
      .string()
      .min(10, t('validCompanyTitle'))
      .when('identityNumberType', {
        is: value => {
          return value && value === '1';
        },
        then: yup.string().required(t('requiredField')).matches(/^[\sa-zA-ZğüşöçİĞÜŞÖÇı0-9!#$%^&*)(+=._-]+$/g, t('validCompanyTitle')).max(40).required(t('requiredField'))
      }),
    taxNumber: yup
      .number(t('validTaxNumberNumeric'))
      .min(999999999, t('validTaxNumberMin'))
      .max(9999999999, t('validTaxNumberMax'))
      .test('isTaxNumberControl', t('validTaxNumber'), function (value) {
        if (value) {
          if (value.toString().length === 10) {
            let v = [];
            let lastDigit = Number(value.toString().charAt(9));
            for (let i = 0; i < 9; i++) {
              let tmp = (Number(value.toString().charAt(i)) + (9 - i)) % 10;
              v[i] = (tmp * 2 ** (9 - i)) % 9;
              if (tmp !== 0 && v[i] === 0) v[i] = 9;
            }
            let sum = v.reduce((a, b) => a + b, 0) % 10;
            return (10 - (sum % 10)) % 10 === lastDigit;
          }
          return false;
        } else {
          return true;
        }
      })
      .when('identityNumberType', {
        is: (value) => {
          return value && value === '1';
        },
        then: yup.number(t('validTaxNumberNumeric')).required(t('requiredField'))
      }),
    identityNumber: yup.string()
      .when(['showIdentityNumber', 'identityNumberType'], {
        is: (showIdentityNumber, identityNumberType) => identityNumberType && showIdentityNumber && identityNumberType === '0',
        then: yup.string().min(11, t('IdentityNumberLengthValidation'))
          .max(11,  t('IdentityNumberLengthValidation'))
          .matches(/^[1-9]{1}[0-9]{9}[0,2,4,6,8]{1}$/,  t('IdentityNumberLengthValidation')).required( t('requiredField'))
      }),
    taxAdministration: yup
      .string()
      .when('identityNumberType', {
        is: value => value && value === '1',
        then: yup.string().min(3, t('validTaxOffice')).max(20, t('validTaxOffice')).required(t('requiredField'))
      }),
    phoneNumber: yup
      .string()
      .phone(null, null, t('profileErrorPhone'))
      .required(t('requiredField')),
    billingAddress: yup
      .string()
      .min(10, t('validBillingAddress'))
      .required(t('requiredField')),
    country: yup
      .string()
      .required(t('requiredField')),
    city: yup
      .string()
      .min(2, t('validCity'))
      .max(40,  t('validCity'))
      .required(t('requiredField')),
    district: yup
      .string()
      .min(2, t('validDistrict'))
      .max(40,  t('validDistrict'))
      .required(t('requiredField')),
    addressDescription: yup
      .string()
      .min(2, t('validAddress'))
      .required(t('requiredField')),
    showIdentityNumber: yup
      .boolean()
      .default(false),
    terms: yup
      .bool()
      .oneOf([true], t('validTerms'))
  });
  const user = useSelector((state) => state.main.user);

  const onSubmit = (values) => {
    const sendValues = {...values};
    sendValues.identityNumber = sendValues.identityNumberType === '0' ? sendValues.identityNumber : sendValues.taxNumber;
    sendValues.name = sendValues.identityNumberType === '0' ? sendValues.name : sendValues.companyTitle;
    delete values.taxNumber;
    delete values.companyTitle;
    onSubmited(sendValues);
  };

  useEffect(() => {
    formikRef.current.setFieldValue('email', user.email);
    formikRef.current.setFieldValue('name', user.name);
    formikRef.current.setFieldValue('surname', user.surname);
  }, []);


  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange={true}
      validateOnBlur={true}
      onSubmit={values => {
        onSubmit(values);
      }}
    >
      {({errors, touched, values, handleChange, handleSubmit}) => (
        <form onSubmit={handleSubmit} style={{marginTop: 30}}>
          <Grid
            container
            spacing={3}
            direction="column"
            alignItems={{xs: 'stretched', sm: 'stretched'}}
            justifyContent="center"
          >
            {/*fatura turu */}
            <Grid item xs={12} md={6}>
              <InputLabel sx={{fontWeight: 600}}>{t('identityNumberType')}</InputLabel>
              <Select
                value={values.identityNumberType}
                fullWidth
                variant="outlined"
                name={'identityNumberType'}
                onChange={handleChange}
              >
                <MenuItem value={'0'}>{t('individual')}</MenuItem>
                <MenuItem value={'1'}>{t('corporate')}</MenuItem>
              </Select>
            </Grid>

            {/* eposta */}
            <Grid item xs={12} md={6}>
              <InputLabel sx={{fontWeight: 600}}>{t('email')}*</InputLabel>
              <TextField
                autoComplete="false"
                variant="outlined"
                fullWidth
                disabled={true}
                name={'email'}
                value={values.email}
                onChange={handleChange}
                error={touched.email && Boolean(errors.email)}
                helperText={touched.email && errors.email}
              />
            </Grid>

            {/* vergi kimlik no */}
            {values.identityNumberType === '1' ?
              <Grid item xs={12} md={6}>
                <InputLabel sx={{fontWeight: 600}}>{t('taxNumber')}*</InputLabel>
                <TextField
                  autoComplete="false"
                  variant="outlined"
                  fullWidth
                  name={'taxNumber'}
                  onChange={handleChange}
                  error={touched.taxNumber && Boolean(errors.taxNumber)}
                  helperText={touched.taxNumber && errors.taxNumber}
                  onInput={(e) => {
                    const value = e.target.value;
                    const length = value.length;
                    const reg = new RegExp('^[0-9]+$');
                    if (length > 10) {
                      e.target.value = value.toString().slice(0, 10);
                    }
                    if ((!reg.test(value))) {
                      e.target.value = '';
                    }
                  }}
                />
              </Grid> : null
            }

            {/* vergi dairesi */}
            {values.identityNumberType === '1' ?
              <Grid item xs={12} md={6}>
                <InputLabel sx={{fontWeight: 600}}>{t('taxAdministration')}*</InputLabel>
                <TextField
                  autoComplete="false"
                  variant="outlined"
                  fullWidth
                  name={'taxAdministration'}
                  onChange={handleChange}
                  error={touched.taxAdministration && Boolean(errors.taxAdministration)}
                  helperText={touched.taxAdministration && errors.taxAdministration}
                />
              </Grid> : null
            }

            {/*ünvan */}
            {values.identityNumberType === '1' ?
              <Grid item xs={12} md={6}>
                <InputLabel sx={{fontWeight: 600}}>{t('companyTitle')}*</InputLabel>
                <TextField
                  autoComplete="false"
                  variant="outlined"
                  fullWidth
                  name={'companyTitle'}
                  onChange={handleChange}
                  error={touched.companyTitle && Boolean(errors.companyTitle)}
                  helperText={touched.companyTitle && errors.companyTitle}
                />
              </Grid> : null
            }

            {/* ad */}
            {values.identityNumberType === '0' ?
              <Grid item xs={12} md={6}>
                <InputLabel sx={{fontWeight: 600}}>{t('name')}*</InputLabel>
                <TextField
                  autoComplete="false"
                  variant="outlined"
                  fullWidth
                  name={'name'}
                  value={values.name}
                  onChange={handleChange}
                  error={touched.name && Boolean(errors.name)}
                  helperText={touched.name && errors.name}
                />
              </Grid> : null
            }

            {/* soyad */}
            {values.identityNumberType === '0' ?
              <Grid item xs={12} md={6}>
                <InputLabel sx={{fontWeight: 600}}>{t('surname')}*</InputLabel>
                <TextField
                  autoComplete="false"
                  variant="outlined"
                  fullWidth
                  name={'surname'}
                  value={values.surname}
                  onChange={handleChange}
                  error={touched.surname && Boolean(errors.surname)}
                  helperText={touched.surname && errors.surname}
                />
              </Grid> : null
            }

            {/*telefon */}
            <Grid item xs={12} md={6}>
              <InputLabel sx={{fontWeight: 600}}>{t('phone')}*</InputLabel>
              <PhoneInput
                country={'tr'}
                preferredCountries={['tr']}
                value={values.phoneNumber}
                onChange={(phoneNumber, country, e) => {
                  handleChange(e);
                }}
                inputProps={{name: 'phoneNumber'}}
                inputStyle={{width: '100%', border: 0, backgroundColor: '#ECEBEE', height: 60, fontSize: '16px'}}
              />
              {
                touched.phoneNumber && <ErrorMessage name="phoneNumber">{msg => <p style={{
                  fontSize: '0.75rem',
                  lineHeight: '1.66',
                  marginTop: '3px',
                  color: '#d32f2f'
                }}>
                  <InfoIcon
                    style={{
                      width: '17px',
                      height: '17px',
                      marginRight: '5px',
                      verticalAlign: 'middle'
                    }}/>
                  <span style={{lineHeight: '17px', verticalAlign: 'middle'}}>{msg}</span>
                </p>}
                </ErrorMessage>
              }
            </Grid>

            {/*fatura adresi */}
            <Grid item xs={12} md={6}>
              <InputLabel sx={{fontWeight: 600}}>{t('addressDescription')}*</InputLabel>
              <Select
                value={values.addressDescription}
                fullWidth
                variant="outlined"
                name={'addressDescription'}
                onChange={handleChange}
                error={touched.addressDescription && Boolean(errors.addressDescription)}
                MenuProps={{
                  style: {
                    maxHeight: 220,
                    maxWidth: 50
                  }
                }}
              >
                <MenuItem value={'Home'}>{t('home')}</MenuItem>
                <MenuItem value={'Work'}>{t('work')}</MenuItem>
              </Select>

              {
                touched.addressDescription && <ErrorMessage name="addressDescription">{msg => <p style={{
                  fontSize: '0.75rem',
                  lineHeight: '1.66',
                  marginTop: '3px',
                  color: '#d32f2f'
                }}>
                  <InfoIcon
                    style={{
                      width: '17px',
                      height: '17px',
                      marginRight: '5px',
                      verticalAlign: 'middle'
                    }}/>
                  <span style={{lineHeight: '17px', verticalAlign: 'middle'}}>{msg}</span>
                </p>}
                </ErrorMessage>
              }
            </Grid>

            {/*ülke */}
            <Grid item xs={12} md={6}>
              <InputLabel sx={{fontWeight: 600}}>{t('profileCountry')}*</InputLabel>

              <Select
                fullWidth
                value={values.country}
                variant="outlined"
                name={'country'}
                onChange={(e) => {
                  handleChange(e);
                  formikRef.current.setFieldValue('city', '');
                  formikRef.current.setFieldValue('district', '');
                }}
                MenuProps={{
                  style: {
                    maxHeight: 220
                  }
                }}
                error={touched.country && Boolean(errors.country)}
                helpertext={touched.country && errors.country}
              >
                {ulkeler.map((item, i) => (
                  <MenuItem value={item} key={i}>{item}</MenuItem>
                ))}
              </Select>
              {
                touched.country && <ErrorMessage name="country">{msg => <p style={{
                  fontSize: '0.75rem',
                  lineHeight: '1.66',
                  marginTop: '3px',
                  color: '#d32f2f'
                }}>
                  <InfoIcon
                    style={{
                      width: '17px',
                      height: '17px',
                      marginRight: '5px',
                      verticalAlign: 'middle'
                    }}/>
                  <span style={{lineHeight: '17px', verticalAlign: 'middle'}}>{msg}</span>
                </p>}
                </ErrorMessage>
              }
            </Grid>

            {/*il */}
            <Grid item xs={12} md={6}>
              <InputLabel sx={{fontWeight: 600}}>{t('profileCity')}*</InputLabel>
              {
                values.country === 'Türkiye' ? <Select
                  fullWidth
                  variant="outlined"
                  value={values.city}
                  name={'city'}
                  onChange={handleChange}
                  error={touched.city && Boolean(errors.city)}
                  MenuProps={{
                    style: {
                      maxHeight: 220
                    }
                  }}
                >
                  {values.country === 'Türkiye' ? il_ilce.map((item, i) => (
                    <MenuItem value={item.il} key={i}>{item.il}</MenuItem>
                  )) : null}
                </Select> : <TextField
                  autoComplete="false"
                  variant="outlined"
                  fullWidth
                  name={'city'}
                  value={values.city}
                  onChange={handleChange}
                  error={touched.city && Boolean(errors.city)}
                />
              }

              {
                touched.city && <ErrorMessage name="city">{msg => <p style={{
                  fontSize: '0.75rem',
                  lineHeight: '1.66',
                  marginTop: '3px',
                  color: '#d32f2f'
                }}>
                  <InfoIcon
                    style={{
                      width: '17px',
                      height: '17px',
                      marginRight: '5px',
                      verticalAlign: 'middle'
                    }}/>
                  <span style={{lineHeight: '17px', verticalAlign: 'middle'}}>{msg}</span>
                </p>}
                </ErrorMessage>
              }
            </Grid>

            {/*ilçe */}
            <Grid item xs={12} md={6}>
              <InputLabel sx={{fontWeight: 600}}>{t('paymentDistrict')}*</InputLabel>
              {
                values.country === 'Türkiye' ? <Select
                  value={values.district}
                  fullWidth
                  variant="outlined"
                  name={'district'}
                  onChange={handleChange}
                  error={touched.district && Boolean(errors.district)}
                  MenuProps={{
                    style: {
                      maxHeight: 220
                    }
                  }}
                >
                  {values.country === 'Türkiye' ? il_ilce.filter(x => x.il === values.city).map((item) => {
                    return (
                      item.ilceleri.map((ilce, i) => (
                        <MenuItem value={ilce} key={i}>{ilce}</MenuItem>
                      ))
                    );
                  }) : null}
                </Select> : <TextField
                  autoComplete="false"
                  variant="outlined"
                  fullWidth
                  name={'district'}
                  value={values.district}
                  onChange={handleChange}
                  error={touched.district && Boolean(errors.district)}
                />
              }

              {
                touched.district && <ErrorMessage name="district">{msg => <p style={{
                  fontSize: '0.75rem',
                  lineHeight: '1.66',
                  marginTop: '3px',
                  color: '#d32f2f'
                }}>
                  <InfoIcon
                    style={{
                      width: '17px',
                      height: '17px',
                      marginRight: '5px',
                      verticalAlign: 'middle'
                    }}
                  />
                  <span style={{lineHeight: '17px', verticalAlign: 'middle'}}>{msg}</span>
                </p>}
                </ErrorMessage>
              }
            </Grid>

            {/* Adres */}
            <Grid item xs={12} md={6}>
              <InputLabel sx={{fontWeight: 600}}>{t('enterAddress')}*</InputLabel>
              <TextField
                autoComplete="false"
                variant="outlined"
                fullWidth
                name={'billingAddress'}
                onChange={handleChange}
                error={touched.billingAddress && Boolean(errors.billingAddress)}
                helperText={touched.billingAddress && errors.billingAddress}
              />
              <Box
                display="flex"
                flexDirection={{xs: 'row', sm: 'row'}}
                alignItems={{xs: 'center', sm: 'center'}}
                justifyContent={'space-between'}
                width={'100%'}
                sx={{marginTop: 3}}
                marginY={1}
              >
                {
                  values.identityNumberType === '0' ? <Typography variant={'subtitle2'}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          value={values.showIdentityNumber}
                          name={'showIdentityNumber'}
                          onChange={handleChange}
                        />
                      }
                      label={
                        <Typography variant={'subtitle2'} sx={{fontWeight: 700, color: 'text.tertiary'}}>
                          <Link
                            component={'a'}
                            color={'primary'}
                            underline={'none'}
                          >
                            {t('identityNumberEnter')}
                          </Link>
                        </Typography>
                      }
                      sx={{
                        '& span': {
                          fontSize: '0.875em'
                        }
                      }}
                    />
                  </Typography> : null
                }
              </Box>
            </Grid>

            {/*Kimlik numarası */}
            {values.identityNumberType === '0' && values.showIdentityNumber ?
              <Grid item xs={12} md={6} sx={{paddingTop: '4px !important'}}>
                <InputLabel sx={{fontWeight: 600}}>{t('profileIdentityNumber')}</InputLabel>
                <TextField
                  autoComplete="false"
                  variant="outlined"
                  fullWidth
                  name={'identityNumber'}
                  onChange={handleChange}
                  onInput={(e) => {
                    const value = e.target.value;
                    const length = value.length;
                    if (length > 11) {
                      e.target.value = value.toString().slice(0, 11);
                    }
                    const reg = new RegExp('^[0-9]*$');
                    if ((!reg.test(value))) {
                      e.target.value = value.toString().slice(0, value.length - 1);
                    }
                  }}
                  error={touched.identityNumber && Boolean(errors.identityNumber)}
                  helperText={touched.identityNumber && errors.identityNumber}
                />

              </Grid> : null
            }

            {/* Sözleşme onayları */}
            <Grid
              item
              container
              xs={12}
              md={6}
              sx={{paddingTop: '2px !important'}}
              justifyContent={{xs: 'center', md: 'end'}}>
              <Box
                display="flex"
                flexDirection={{xs: 'row', sm: 'row'}}
                alignItems={{xs: 'center', sm: 'center'}}
                justifyContent={'space-between'}
                width={'100%'}
              >
                <Typography variant={'subtitle2'}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={values.terms}
                        name={'terms'}
                        onChange={handleChange}
                      />}
                    label={
                      <Typography variant={'subtitle2'} sx={{fontWeight: 700, color: 'text.tertiary'}}>
                        <Link
                          component={'a'}
                          color={errors.terms && errors.terms ? '#d32f2f' : 'primary'}
                          href={'/'}
                          underline={'none'}
                        >
                          {t('preliminaryInformationConditions')} {' '}
                        </Link>
                        ve
                        <Link
                          component={'a'}
                          color={errors.terms && errors.terms ? '#d32f2f' : 'primary'}
                          href={'/'}
                          underline={'none'}
                        >
                          {' '} {t('termsOfConditions')} {' '}
                        </Link>
                        {errors.terms && errors.terms ? t('mustReadAndApprove') : t('readAndApprove')}
                      </Typography>
                    }
                    sx={{
                      '& span': {
                        fontSize: '0.875em'
                      }
                    }}
                  />
                </Typography>
              </Box>
              <Box
                flexDirection={{xs: 'column', sm: 'column'}}
                sx={{marginTop: 5, width: '100%'}}
              >
                <Button
                  color={'primary'}
                  size={'large'}
                  variant={'contained'}
                  type={'submit'}
                  sx={{fontWeight: 500, width: 150, float: 'right'}}>
                  {type === 'trial' ? t('save') : t('next')}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

export default InvoiceForm;

InvoiceForm.propTypes = {
  type: PropTypes.string.isRequired,
  onSubmited: PropTypes.func.isRequired
};
