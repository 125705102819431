import Cookies from 'universal-cookie';

const cookies = new Cookies();

function token() {
  let json = cookies.get('token');
  if (json) {
    return json;
  }
  return null;
}

function publicToken() {
  let json = cookies.get('public-token');
  if (json) {
    return json;
  }
  return null;
}

export default {
  getToken: token,
  getPublicToken: publicToken
};
