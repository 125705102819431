import React from 'react';
import PropTypes from 'prop-types';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import {Button} from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Typography from '@mui/material/Typography';
import Moment from 'react-moment';
import DownloadIcon from '@mui/icons-material/Download';
import {useTranslation} from 'react-i18next';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '0px solid #000',
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
  color: '#000'
};

function DetailModal({selected, showDetailModal, setShowDetailModal}) {
  const {t} = useTranslation();

  return (
    <div>
      {
        selected && <Modal
          open={showDetailModal}
          onClose={() => setShowDetailModal(false)}
        >
          <Box sx={style}>
            <Box
              display="flex"
              flexDirection={'row'}
              alignContent={'center'}
              justifyContent={'end'}
            >
              <Button onClick={() => setShowDetailModal(false)}><CloseRoundedIcon/></Button>
            </Box>
            <Typography
              id="modal-modal-title"
              variant="h5"
              sx={{textAlign: 'left', fontWeight: 600, mb: 3, mt: -2}}>
              {selected.productName}
            </Typography>

            <Typography sx={{fontWeight: 600}} mb={2}>
              {t('paymentDetail')}
            </Typography>
            <Typography mb={2}>
              {selected.isTrial ? t('freeMonthLicense') : selected.price + t('monthLicense')}
            </Typography>
            <Typography sx={{fontWeight: 600}} mb={2}>
              {t('totalPrice')}
              : {(selected.isTrial ? t('freeLicense') : selected.totalPrice + '$')}
            </Typography>
            <Typography mb={2}>
              {t('purchaseDate')} :
              {
                <Moment format="DD/MM/YYYY hh:mm">
                  {selected.dateOfPurchase}
                </Moment>
              }
            </Typography>
            <Typography sx={{fontWeight: 600}} mb={2}>
              {t('billingAddress')}
            </Typography>
            <Typography mb={2}>
              {selected.billingAddress}
            </Typography>

            <center>
              <Button
                startIcon={<DownloadIcon/>}
                variant="contained"
                target={'_blank'}
                href={selected?'https://portal.uyumsoft.com.tr/Genel/Fatura/'+selected.token:null}
                sx={{width: 180, mt: 2, mr: 1}}
              >
                {t('downloadFile')}
              </Button>

              {/*<Button*/}
              {/*  variant="contained"*/}
              {/*  sx={{width: 180, mt: 2, ml: 1}}*/}
              {/*  onClick={unsubscribe}*/}
              {/*>*/}
              {/*  {t('cancelSubscription')}*/}
              {/*</Button>*/}
            </center>

          </Box>
        </Modal>
      }
    </div>
  );
}

DetailModal.propTypes = {
  selected: PropTypes.object,
  showDetailModal: PropTypes.bool,
  setShowDetailModal: PropTypes.func
};

export default DetailModal;
