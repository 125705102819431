export const light = {
  alternate: {
    main: '#B71C1C', // Replaced with red
    dark: '#edf1f7',
  },
  cardShadow: 'rgba(183, 28, 28, .11)', // Adjusted to match red tones
  mode: 'light',
  primary: {
    main: '#B71C1C',  // Replaced with red
    light: '#FFCDD2', // Adjusted light red
    dark: '#D32F2F',  // Adjusted dark red
    contrastText: '#fff',
  },
  secondary: {
    light: '#EFEFEF',
    main: '#EFEFEF',
    dark: '#ECEBEE',
    contrastText: '#000',
  },
  google: {
    light: '#FFFFFF',
    main: '#FFFFFF',
    dark: '#FFFFFF',
    contrastText: '#4A4B4B',
  },
  text: {
    primary: '#B71C1C', // Replaced with red
    secondary: '#060000',
    tertiary: '#4A4B4B'
  },
  divider: 'rgba(183, 28, 28, 0.12)', // Adjusted to match red tones
  background: {
    paper: '#fff',
    default: '#fff',
    level2: '#f5f5f5',
    level1: '#fff',
  },
};

export const dark = {
  alternate: {
    main: '#D32F2F', // Replaced with red
    dark: '#edf1f7',
  },
  cardShadow: 'rgba(183, 28, 28, .11)', // Adjusted to match red tones
  mode: 'dark', // Adjusted to "dark" for consistency
  primary: {
    main: '#D32F2F',  // Replaced with red
    light: '#E65A60',
    dark: '#8A151A',
    contrastText: '#fff',
  },
  secondary: {
    light: '#ffb74d',
    main: '#f9b934',
    dark: '#FF9800',
    contrastText: 'rgba(0, 0, 0, 0.87)',
  },
  text: {
    primary: '#FFCDD2', // Adjusted to light red
    secondary: '#D32F2F', // Adjusted to red
  },
  divider: 'rgba(183, 28, 28, 0.12)', // Adjusted to match red tones
  background: {
    paper: '#fff',
    default: '#fff',
    level2: '#f5f5f5',
    level1: '#fff',
  },
};
