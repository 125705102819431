import CachedIcon from '@mui/icons-material/Cached';
import DeleteIcon from '@mui/icons-material/Delete';
import LockIcon from '@mui/icons-material/Lock';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import {Button, LinearProgress, TextField} from '@mui/material';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Paper from '@mui/material/Paper';
import {alpha} from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import {visuallyHidden} from '@mui/utils';
import PropTypes from 'prop-types';
import React, {useEffect, useRef} from 'react';
import {useSelector} from 'react-redux';
import Swal from 'sweetalert2';
import ChangePasswordModalMultiple from './ChangePasswordModalMultiple';
import AddUserModal from './AddUserModal';
import EditIcon from '@mui/icons-material/Edit';
import EditUserModal from './EditUserModal';
import ChangePasswordModalSingle from './ChangePasswordModalSingle';
import TableResponsive from '../../../../common/TableResponsive';
import {useHistory} from 'react-router-dom';
import {CompanyAdminServices} from 'services/index';
import {Roles} from '../../../../enums';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import {useTranslation} from 'react-i18next';


const EnhancedTableToolbar = (props) => {
  const {t} = useTranslation();
  const {numSelected} = props;
  const history = useHistory();
  const filterButtonRef = useRef(null);
  const [roles, setRoles] = React.useState([]);
  const query = new URLSearchParams(location.search);
  const [filterForm, setFilterForm] = React.useState({
    name: '',
    surname: '',
    searchTerm: '',
    email: '',
    isActive: true,
    role: '',
    pageNumber: 1,
    rowCount: 10,
    sortColumn: '',
    sortDirection: 0,
  });

  useEffect(() => {
    const filterData = {
      name: query.get('name') ? query.get('name') : '',
      surname: query.get('surname') ? query.get('surname') : '',
      searchTerm: query.get('searchTerm') ? query.get('searchTerm') : '',
      email: query.get('email') ? query.get('email') : '',
      isActive: query.get('isActive') === 'true',
      role: query.get('role') ? parseInt(query.get('role')) : null,
      pageNumber: query.get('pageNumber') ? parseInt(query.get('pageNumber')) : 1,
      rowCount: query.get('rowCount') ? parseInt(query.get('rowCount')) : 10,
      sortColumn: query.get('sortColumn'),
      sortDirection: query.get('sortDirection') ? parseInt(query.get('sortDirection')) : 0,
    };
    setFilterForm(filterData);
  }, [location.search]);

  const isActiveUser = () => {
    return query.get('isActive') === 'true';
  };

  const onFilterChange = (name, value) => {
    setFilterForm({...filterForm, ...{[name]: value}});
  };

  const handleFilterChange = (e) => {
    Object.keys(filterForm).forEach((key) => {
      const value = filterForm[key];
      if (value === null || value === '' || value === undefined) {
        query.delete(key);
      } else {
        if (query.has(key)) {
          query.set(key, value);
        } else {
          query.append(key, value);
        }
      }
    });
    history.push({pathname: location.pathname, search: query.toString()});
    e.preventDefault();
  };

  useEffect(() => {
    roleMap();
  }, []);

  const roleMap = () => {
    const roleList = Object.keys(Roles)
      .map(key => ({id: Roles[key], name: key}));
    setRoles(roleList);
  };

  return (
    <Toolbar
      sx={{
        pl: {sm: 2},
        pr: {xs: 1, sm: 1},
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity)
        })
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{flex: '1 1 100%'}}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          Toplam {numSelected} seçili
        </Typography>
      ) : (
        <Typography
          sx={{flex: '1 1 100%'}}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {
            isActiveUser() && <Button
              startIcon={<PersonAddAltIcon/>}
              variant="contained"
              onClick={() => props.setShowAddUserModal(true)}
            >
              {t('addUser')}
            </Button>
          }
          <Button
            startIcon={<CachedIcon/>}
            sx={{ml: isActiveUser() ? 2 : 0}}
            variant="outlined"
            onClick={() => props.refleshData(true)}
          >
            {t('reflesh')}
          </Button>
        </Typography>
      )}
      {numSelected > 0 ? (
        <div style={{display: 'flex'}}>
          {
            isActiveUser() ? <>
              <Button
                disabled={numSelected > 1}
                variant="outlined"
                sx={{width: 180}}
                startIcon={<EditIcon/>}
                onClick={props.editSelected}
              >
                {t('editUser')}
              </Button>

              <Button
                variant="outlined"
                sx={{width: 180, ml: 2}}
                startIcon={<DeleteIcon/>}
                onClick={props.deleteSelected}
              >
                {numSelected > 1 ? t('deleteUsers') : t('deleteUser')}
              </Button>
              <Button
                onClick={props.paswordResetSelected}
                variant="outlined"
                sx={{width: 180, ml: 2}}
                startIcon={<LockIcon/>}
              >
                {numSelected > 1 ? t('resetUsersPassword') : t('resetUserPassword')}
              </Button>
            </> : <Button
              variant="outlined"
              sx={{width: 220, ml: 2}}
              startIcon={<SettingsBackupRestoreIcon/>}
              onClick={props.reactiveSelected}
            >
              {numSelected > 1 ? t('restoreUsers') : t('restoreUser')}
            </Button>

          }
        </div>
      ) : (
        <Tooltip title={t('filteringCriteria')}>
          <form onSubmit={handleFilterChange}>
            <div style={{display: 'flex'}}>
              <FormControl sx={{minWidth: 120}}>
                <TextField
                  label={t('searchText')}
                  name={'searchTerm'}
                  size="small"
                  onChange={(e) => {
                    onFilterChange('searchTerm', e.target.value);
                  }}
                  value={filterForm.searchTerm}
                  id="outlined-basic"
                  variant="outlined" sx={{ml: 1, width: 300}}/>
              </FormControl>
              <FormControl sx={{minWidth: 120}}>
                <InputLabel size={'small'}>{t('role')}</InputLabel>
                <Select
                  size="small"
                  value={filterForm.role}
                  variant="outlined"
                  name={'role'}
                  sx={{ml: 1, width: 200}}
                  onChange={(e) => {
                    onFilterChange('role', e.target.value);
                    filterButtonRef.current.click();
                  }}>
                  <MenuItem value={''}> {t('role')} </MenuItem>
                  {roles.map((item, i) => {
                    return <MenuItem value={item.id} key={i}>
                      {item.id === Roles.CompanyUser ? t('user') : ''}
                      {item.id === Roles.CompanyAdmin ? t('adminRole') : ''}
                    </MenuItem>;
                  })}
                </Select>
              </FormControl>
              <button
                disabled={filterForm.searchTerm && filterForm.searchTerm.length !== 0 && filterForm.searchTerm.length < 3}
                ref={filterButtonRef} style={{display: 'none'}} type="submit">
                Submit
              </button>
            </div>
          </form>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  deleteSelected: PropTypes.func,
  editSelected: PropTypes.func,
  paswordResetSelected: PropTypes.func,
  setShowAddUserModal: PropTypes.func,
  reactiveSelected: PropTypes.func,
  refleshData: PropTypes.func
};

export default function DataTable() {
  const {t} = useTranslation();
  const history = useHistory();
  const [data, setData] = React.useState({
    hasNextPage: true,
    hasPreviousPage: false,
    indexFrom: 0,
    items: null,
    pageIndex: 0,
    pageSize: 10,
    totalCount: 21,
    totalPages: 3
  });
  const [filter, setFilter] = React.useState({
    name: null,
    surname: null,
    searchTerm: null,
    email: null,
    isActive: true,
    role: null,
    pageNumber: 1,
    rowCount: 10,
    sortColumn: null,
    sortDirection: 0,
  });
  const [selected, setSelected] = React.useState([]);
  const [isGetDataOpen, setIsGetDataOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const user = useSelector((state) => state.main.user);
  const [showAddUserModal, setShowAddUserModal] = React.useState(false);
  const [showEditUserModal, setShowEditUserModal] = React.useState(false);
  const [showChangePasswordModalSingle, setShowChangePasswordModalSinlge] = React.useState(false);
  const [showChangePasswordModalMultiple, setShowChangePasswordModalMultiple] = React.useState(false);
  const [editUser, setEditUser] = React.useState(null);
  const columns = [
    {
      id: 'name',
      disablePadding: true,
      label: t('nameSurname')
    },
    {
      id: 'email',
      disablePadding: false,
      label: t('email')
    },
    {
      id: 'licenses',
      disablePadding: false,
      label: t('licenses')
    },
    {
      id: 'modified',
      disablePadding: false,
      label: t('dateOfDelete')
    }
  ];

  const query = new URLSearchParams(location.search);

  useEffect(() => {
    const filterData = {
      name: query.get('name'),
      surname: query.get('surname'),
      searchTerm: query.get('searchTerm'),
      email: query.get('email'),
      isActive: query.get('isActive') === 'true',
      role: query.get('role') ? parseInt(query.get('role')) : null,
      pageNumber: query.get('pageNumber') ? parseInt(query.get('pageNumber')) : 1,
      rowCount: query.get('rowCount') ? parseInt(query.get('rowCount')) : 10,
      sortColumn: query.get('sortColumn'),
      sortDirection: query.get('sortDirection') ? parseInt(query.get('sortDirection')) : 0,
    };
    setFilter(filterData);
    setIsGetDataOpen(true);
  }, [location.search]);

  useEffect(() => {
    if (isGetDataOpen) {
      getList();
    }
  }, [isGetDataOpen]);

  useEffect(() => {
    setSelected([]);
  }, [filter?.isActive]);

  useEffect(() => {
    if (!showEditUserModal) {
      setEditUser(null);
    }
  }, [showEditUserModal]);

  const refleshData = () => {
    getList();
  };

  const onFilterChange = (name, value) => {
    if (value === null || value === '' || value === undefined) {
      query.delete(name);
    } else {
      if (query.has(name)) {
        query.set(name, value);
      } else {
        query.append(name, value);
      }
    }
    history.push({pathname: location.pathname, search: query.toString()});
  };

  const getList = () => {
    setLoading(true);
    const promise = CompanyAdminServices.get(filter);
    promise.then((response) => {
      if (response) {
        setData(response.data.result);
      }
      setLoading(false);
      setIsGetDataOpen(false);
    });
  };

  const editSelected = () => {
    const user = data.items.find(item => item.userId === (selected && selected[0].userId));
    setEditUser(user);
    setShowEditUserModal(true);
  };

  const isActiveUser = () => {
    return (filter && filter.isActive);
  };

  const handleRequestSort = (event, property) => {
    onFilterChange('sortDirection', parseInt(filter.sortDirection) === 0 ? 1 : 0);
    onFilterChange('sortColumn', property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      console.log('data',data);
      const newSelecteds = data.items.map((n) => n);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, user) => {
    let newSelected = [...selected];
    const selectedIndex = newSelected.findIndex((item) => item.userId === user.userId);
    if (selectedIndex === -1) {
      newSelected = [...newSelected,...[user]];
    } else {
      newSelected = newSelected.filter((item) => item.userId !== user.userId);
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    onFilterChange('pageNumber', newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    onFilterChange('rowCount', parseInt(event.target.value, 10));
    onFilterChange('pageNumber', 1);
  };

  const deleteSelected = () => {
    if (selected.findIndex((item)=> user.id === item.userId) !== -1) {
      Swal.fire({
        icon: 'error',
        title: t('warning'),
        text: t('removeMe'),
      });
      return;
    }

    Swal.fire({
      width: 500,
      icon: 'warning',
      title: t('areYouSure'),
      html:
        selected.length + ' '+t('userSelected')+'<br/> ' +
         t('areYouSureYouWantToDelete')+'<br/>' +
          t('deletedUsersWithin30Days'),
      showDenyButton: true,
      confirmButtonText: t('deleteUsers'),
      denyButtonText: t('cancel')
    }).then((result) => {
      if (result.isConfirmed) {
        CompanyAdminServices.deactive(selected.map((item)=>item.userId)).then((response) => {
          if (response) {
            Swal.fire(t('success'), selected.length > 1 ? t('usersDeleted') :  t('userDeleted'), 'success');
            setSelected([]);
            refleshData();
          }
        });
      }
    });
  };

  const reactiveSelected = () => {
    Swal.fire({
      width: 400,
      title: t('areYouSure'),
      text: t('activeUserMessage'),
      confirmButtonText: t('approve'),
      cancelButtonText: t('cancel'),
      icon: 'warning',
      showCancelButton: true

    }).then((result) => {
      if (result.isConfirmed) {
        CompanyAdminServices.activate(selected.map((item)=>item.userId)).then((response) => {
          if (response) {
            Swal.fire(t('success'), selected.length > 1 ? t('usersAreActivated') : t('userAreActivated'), 'success');
            refleshData();
          }
        });
      }
    });
  };


  const isSelected = (id) => selected.findIndex((item) => item.userId === id) !== -1;



  const EnhancedTableHead = (props)=> {
    const query = new URLSearchParams(location.search);
    const {onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort} = props;
    const isActiveUser = () => {
      return query.get('isActive') === 'true';
    };
    const createSortHandler = (event, property) => {
      onRequestSort(event, property);
    };

    const onSelectAll = (event) => {
      onSelectAllClick(event);
    };
    return (
      <TableHead sx={{backgroundColor: '#ECEBEE'}}>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAll}
              inputProps={{
                'aria-label': 'select all desserts'
              }}
            />
          </TableCell>
          {columns.map((colItem) => (
            <>
              {
                colItem.id === 'name' && <TableCell
                  key={colItem.id}
                  align={'left'}
                  padding={colItem.disablePadding ? 'none' : 'normal'}
                  sortDirection={orderBy === colItem.id ? order : false}
                  onClick={(event) => {
                    createSortHandler(event, colItem.id);
                  }}
                >
                  <TableSortLabel
                    active={orderBy === colItem.id}
                    direction={orderBy === colItem.id ? order : 'asc'}
                  >
                    {colItem.label}
                    {orderBy === colItem.id ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
              }
              {
                colItem.id === 'email' && <TableCell
                  key={colItem.id}
                  align={'left'}
                  padding={colItem.disablePadding ? 'none' : 'normal'}
                  sortDirection={orderBy === colItem.id ? order : false}
                  onClick={(event) => {
                    createSortHandler(event, colItem.id);
                  }}
                >
                  <TableSortLabel
                    active={orderBy === colItem.id}
                    direction={orderBy === colItem.id ? order : 'asc'}
                  >
                    {colItem.label}
                    {orderBy === colItem.id ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
              }
              {
                colItem.id === 'licenses' && isActiveUser() && <TableCell
                  key={colItem.id}
                  align={'left'}
                  padding={colItem.disablePadding ? 'none' : 'normal'}
                  sortDirection={orderBy === colItem.id ? order : false}
                  onClick={(event) => {
                    createSortHandler(event, colItem.id);
                  }}
                >
                  <TableSortLabel
                    active={orderBy === colItem.id}
                    direction={orderBy === colItem.id ? order : 'asc'}
                  >
                    {colItem.label}
                    {orderBy === colItem.id ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
              }
              {
                colItem.id === 'modified' && !isActiveUser() && <TableCell
                  key={colItem.id}
                  align={'left'}
                  padding={colItem.disablePadding ? 'none' : 'normal'}
                  sortDirection={orderBy === colItem.id ? order : false}
                  onClick={(event) => {
                    createSortHandler(event, colItem.id);
                  }}
                >
                  <TableSortLabel
                    active={orderBy === colItem.id}
                    direction={orderBy === colItem.id ? order : 'asc'}
                  >
                    {colItem.label}
                    {orderBy === colItem.id ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
              }
            </>
          ))}
        </TableRow>
      </TableHead>
    );
  };
  EnhancedTableHead.propTypes = {
    onSelectAllClick: PropTypes.func,
    order: PropTypes.string,
    orderBy: PropTypes.string,
    numSelected: PropTypes.number,
    rowCount: PropTypes.number,
    onRequestSort: PropTypes.func
  };

  return (
    <Box sx={{width: '100%'}}>
      <Paper sx={{width: '100%', mb: 2}}>
        <TableResponsive style={{minWidth: '900px'}}>
          <EnhancedTableToolbar
            numSelected={selected.length}
            deleteSelected={deleteSelected}
            reactiveSelected={reactiveSelected}
            paswordResetSelected={() => {
              if (selected.map((item)=>item.userId).includes(user && user.id) && selected.length > 1) {
                Swal.fire({
                  width: 400,
                  title: t('warning'),
                  icon: 'warning',
                  html: t('passwordResetOnlyOtherUserText'),
                  confirmButtonText: t('confirmButtonText')
                });
              } else {
                selected.length > 1 ? setShowChangePasswordModalMultiple(true) : setShowChangePasswordModalSinlge(true);
              }
            }}
            setShowAddUserModal={setShowAddUserModal}
            refleshData={refleshData}
            editSelected={editSelected}
          />
          <TableContainer style={{position: 'relative'}}>
            <LinearProgress color="primary" style={{
              opacity: loading ? 1 : 0,
              transition: 'all .3s linear',
              position: 'absolute',
              left: 0,
              right: 0,
              top: 0
            }}/>
            <Table
              aria-labelledby="tableTitle">
              <EnhancedTableHead
                numSelected={selected.length}
                order={parseInt(filter && filter.sortDirection ? filter.sortDirection : 0) === 1 ? 'asc' : 'desc'}
                orderBy={filter && filter.sortColumn ? filter.sortColumn : 'name'}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={data.items ? data.items.length : 0}
              />
              <TableBody>
                {data.items && data.items.map((row, index) => {
                  const isItemSelected = isSelected(row.userId);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={index}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          // disabled={user && user.email === row.email}
                          inputProps={{
                            'aria-labelledby': labelId
                          }}
                        />
                      </TableCell>


                      {columns.map((colItem) => (
                        <>
                          {
                            colItem.id === 'name' && <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              padding="none"
                            >
                              {row.name + ' ' + row.surname}
                            </TableCell>
                          }
                          {
                            colItem.id === 'email' && <TableCell align="left">{row.email}</TableCell>
                          }

                          {
                            colItem.id === 'modified' && !isActiveUser() &&
                            <TableCell align="left">{row.modified}</TableCell>
                          }

                          {
                            colItem.id === 'licenses' && isActiveUser() && <TableCell align="left">
                              {row.roleId === 4 ? t('adminRole') : ''}
                              {row.roleId !== 4 ? t('otherRole') : ''}
                            </TableCell>
                          }
                        </>
                      ))}
                    </TableRow>
                  );
                })}
                {data.items?.length == 0 && (
                  <TableRow
                    style={{
                      height: 33
                    }}
                  >
                    <TableCell colSpan={6}>
                      <center>{t('noData')}</center>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={data.totalCount ? data.totalCount : 0}
            rowsPerPage={filter ? parseInt(filter.rowCount) : 10}
            page={filter ? filter.pageNumber - 1 : 0}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={t('numberOfRows')}
            labelDisplayedRows={(
              {
                from,
                to,
                count
              }) => {
              return `${from}–${to} / ${t('total')} ${count !== -1 ? count : `more than ${to}`}`;
            }}
          />
        </TableResponsive>
      </Paper>

      <ChangePasswordModalMultiple
        selected={selected}
        setSelected={setSelected}
        showChangePasswordModal={showChangePasswordModalMultiple}
        setShowChangePasswordModal={setShowChangePasswordModalMultiple}
      />

      <AddUserModal
        showAddUserModal={showAddUserModal}
        setShowAddUserModal={setShowAddUserModal}
        onSubmitEvent={refleshData}
      />

      {
        editUser && <EditUserModal
          selected={editUser}
          showEditUserModal={showEditUserModal}
          setShowEditUserModal={setShowEditUserModal}
          onSubmitEvent={() => {
            refleshData();
            setSelected([]);
          }}
        />
      }

      <ChangePasswordModalSingle
        selected={selected}
        loginUserName={user && user.name}
        setSelected={setSelected}
        showEditUserModal={showChangePasswordModalSingle}
        setShowEditUserModal={setShowChangePasswordModalSinlge}
      />
    </Box>
  );
}
