import {Ajax} from '../utils/index';

export default {
  getCurrentProduct() {
    let promise = Ajax.getPromise('/payment/get-current-product');
    return promise;
  },
  cancelPayment(id, invoiceNo) {
    let promise = Ajax.getPromise('/payment/cancel-payment/' + id + '/' + invoiceNo);
    return promise;
  },
  paymentRequest(selected) {
    let promise = Ajax.postPublicPromise('/payment/pay', selected);
    return promise;
  },
  getAllPackages(currency) {
    let promise = Ajax.getPublicPromise('/payment/get-all-products/' + currency);
    return promise;
  },
};
