import React from 'react';
import PropTypes from 'prop-types';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Slide from '@mui/material/Slide';


const HideOnScroll = ({children}) => {
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
};

HideOnScroll.propTypes = {
  children: PropTypes.node.isRequired,
};

const BodyMain = ({children}) => {

  return (
    <div>
      <main style={{minHeight: '100vh'}}>
        {children}
      </main>
    </div>
  );
};

BodyMain.propTypes = {
  children: PropTypes.node,
};

export default BodyMain;
