import React, {useEffect} from 'react';
import DataTable from './components/DataTable';
import {Typography} from '@mui/material';
import Grid from '@mui/material/Grid';
import {useTranslation} from 'react-i18next';

function Users() {
  const {t} = useTranslation();
  const [isActive, setIsActive] = React.useState(true);
  const query = new URLSearchParams(location.search);
  useEffect(() => {
    setIsActive(query.get('isActive') === 'true');
  }, [location.search]);

  return (<Grid
    item
    xs={12}
    sm={8}
    md={9}
    xl={10} paddingTop={window.innerWidth > 600 ? 15 : 10}
    paddingLeft={window.innerWidth > 1180 ? 15 : 3}
    paddingRight={window.innerWidth > 1180 ? 15 : 3}>
    <Typography
      variant="h4"
      sx={{fontWeight: 600, color: 'red', mb: 5}}>
      {
        isActive ? t('activeUsers') : t('deactiveUsers')
      }
    </Typography>
    <DataTable/>
  </Grid>);
}

export default Users;
