import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import {Button, FormControl, FormLabel, Radio, RadioGroup, Select} from '@mui/material';
import Box from '@mui/material/Box';
import FormGroup from '@mui/material/FormGroup';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import React, {useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import AddIcon from '@mui/icons-material/Add';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import InputLabel from '@mui/material/InputLabel';
import {Roles} from '../../../../enums';
import {Formik, ErrorMessage} from 'formik';
import * as Yup from 'yup';
import InfoIcon from '@mui/icons-material/Info';
import {CompanyAdminServices} from 'services/index';
import Swal from 'sweetalert2';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import {useSelector} from 'react-redux';
import Grid from '@mui/material/Grid';
import Moment from 'react-moment';
import {useTranslation} from 'react-i18next';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: '100%',
  bgcolor: 'background.paper',
  border: '0px solid #000',
  borderRadius: 2,
  boxShadow: 24,
  p: 4
};
const userModel = {
  name: '',
  surname: '',
  role: '',
  email: '',
  password: '',
};

Yup.addMethod(Yup.string, 'emailSyntaxCheck', function (errorMessage) {
  return this.test('test-card-length', errorMessage, function (value) {
    const {path, createError} = this;
    const re = /^[a-zA-Z0-9.'_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    return re.test(String(value).toLowerCase()) ? true : createError({path, message: errorMessage});
  });
});


function AddUserModal({setShowAddUserModal, showAddUserModal, onSubmitEvent}) {
  const formikRef = useRef();
  const {t} = useTranslation();
  const [showSuccessModal, setShowSuccessModal] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [products, setProducts] = React.useState([]);
  const [approvalEmailLoading, setApprovalEmailLoading] = React.useState(false);
  const [roles, setRoles] = React.useState([]);
  const [response, setResponse] = React.useState({sendEmail: false, userForm: null});
  const [emailCheckResults, setEmailCheckResults] = React.useState([{email:null, emailExists:false}]);
  const [activeStep, setActiveStep] = React.useState(0);
  const [licence, setLicence] = React.useState(null);
  const steps = [t('addUser'), t('licenseSelect')];
  const user = useSelector((state) => state.main.user);
  const [sendValues, setSendValues] = React.useState(null);

  const validationSchema = Yup.object().shape({
    sendEmail: Yup
      .bool(),
    userForm: Yup.array().of(
      Yup.object().shape({
        name: Yup.string()
          .matches(/^'?(?:\p{L}\p{M}*)+(?:['\s](?:\p{L}\p{M}*)+)*'?$/u, t('validName')).max(40)
          .min(2, t('validName'))
          .required(t('requiredField')),
        surname: Yup.string()
          .matches(/^'?(?:\p{L}\p{M}*)+(?:['\s](?:\p{L}\p{M}*)+)*'?$/u, t('validSurname')).max(40)
          .min(2, t('validSurname'))
          .required(t('requiredField')),
        role: Yup.string()
          .required(t('requiredField')),
        email: Yup
          .string()
          .trim()
          .email(t('validEmail'))
          .matches(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/, t('validEmail'))
          .emailSyntaxCheck(t('validEmail'))
          .required(t('requiredField')),
        password: Yup
          .string()
          .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/, t('validPasswordRegex'))
          .required(t('validPassword'))
          .min(8, t('validPasswordMin'))
      })
    )
  });

  let initialValues = {
    sendEmail: false,
    userForm: [{
      name: '',
      surname: '',
      role: '',
      email: '',
      password: '',
    }]
  };

  const checkEmail = async (emailList) =>{
    const data = await CompanyAdminServices.checkEmail(emailList).then((response) => {
      return response?.data.result;
    });
    return data;
  };

  const onSubmit = async (values) => {
    setSendValues(values);
    const result = await checkEmail(values.userForm.map((item)=>item.email));
    setEmailCheckResults(result);
    const emailCheck = result.filter((item)=>item.emailExists === true);
    if (emailCheck && emailCheck.length === 0) {
      setActiveStep(1);
    }
  };

  const onSave = async () => {
    if (licence === null) {
      return;
    }
    let value = {...sendValues};
    let users = value.userForm.map((item) => {
      item.sendEmail = value.sendEmail;
      item.packageToken = licence;
      return item;
    });
    CompanyAdminServices.save({users}).then((response)=>{
      if (response){
        setShowAddUserModal(false);
        if (value.sendEmail) {
          let timerInterval;
          Swal.fire({
            width: 500,
            title: value.userForm && value.userForm.length +' '+ t('userAdded'),
            timer: 10000,
            timerProgressBar: true,
            showCloseButton: false,
            showConfirmButton: true,
            showDenyButton: false,
            text: t('userAddedSuccessMessage'),
            willClose: () => {
              clearInterval(timerInterval);
            }
          });
          setSendValues(null);
          getAllProducts();
        } else {
          setResponse(value);
          setShowSuccessModal(true);
        }
        setActiveStep(0);
        onSubmitEvent();
      } else {
        initialValues.userForm = users;
      }
    });
  };

  const getAllProducts = () => {
    const filter = {
      userId:user.id,
    };
    const promise = CompanyAdminServices.getAllProducts(filter);
    promise.then(async (response)=> {
      let data = [...response.data.result];
      data = data.filter((item)=>item.packageStatus === 1);
      setProducts(data);
    });
  };

  const onLicenceChange = (e) => {
    setLicence(e.target.value);
  };

  useEffect(() => {
    roleMap();
    getAllProducts();
  }, []);

  const roleMap = () => {
    const roleList = Object.keys(Roles)
      .map(key => ({id: Roles[key], name: key}));
    setRoles(roleList);
  };

  const sendApprovalEmail = (email) => {
    setApprovalEmailLoading(true);
    CompanyAdminServices.sendCompanyApprovalEmail({email}).then((response) => {
      if (response) {
        let timerInterval;
        Swal.fire({
          width: 400,
          title: t('success'),
          timer: 10000,
          timerProgressBar: true,
          showCloseButton: false,
          showConfirmButton: true,
          showDenyButton: false,
          text: t('sendMailSuccessMessage'),
          willClose: () => {
            clearInterval(timerInterval);
          }
        });
      }
      setApprovalEmailLoading(false);
    });
  };

  return (
    <>
      <Modal
        open={showAddUserModal}
        onClose={() => {setShowAddUserModal(false); setActiveStep(0);}}>
        <Box sx={style}>
          <Box>

            <Box
              display="flex"
              flexDirection={'row'}
              alignContent={'center'}
              justifyContent={'end'}
            >
              <Button onClick={() => {setShowAddUserModal(false); setActiveStep(0);}}><CloseRoundedIcon/></Button>
            </Box>
            {/*<Typography*/}
            {/*  id="modal-modal-title"*/}
            {/*  variant="h6"*/}
            {/*  component="h2"*/}
            {/*  sx={{textAlign: 'center', fontWeight: 600, mb: 2, mt: -2}}>*/}
            {/*  Kullanıcı Ekle*/}
            {/*</Typography>*/}

            <Stepper style={{marginTop:10, marginBottom:40}} activeStep={activeStep}>
              {steps.map((label) => {
                const stepProps = {};
                const labelProps = {};
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>

            {
              <Formik
                innerRef={formikRef} initialValues={initialValues}
                validationSchema={validationSchema}
                validateOnChange={false}
                validateOnBlur={false}
                enableReinitialize={true}
                onSubmit={values => {
                  onSubmit(values);
                }}>
                {({values, setValues, handleSubmit, handleBlur, errors, touched, handleChange}) => (
                  <Box style={{maxWidth:'100%', overflow:'auto'}}>
                    <Box style={{width:1030}}>
                      <form style={{display:activeStep === 0?'block':'none'}}  onSubmit={handleSubmit}>
                        <Box style={{maxWidth:'100%', overflow:'auto'}}>
                          <FormGroup>
                            <Box style={{width:'100%',maxHeight:300, overflowX:'auto', overflow:'hidden auto'}}>
                              {
                                values.userForm.map((item, i) => {
                                  const userFormErrors = errors.userForm?.length && errors.userForm[i] || {};
                                  const userFormTouched = touched.userForm?.length && touched.userForm[i] || {};
                                  return (
                                    <div
                                      key={i}
                                    >
                                      <Box
                                        component="form"
                                        sx={{
                                          '& > :not(style)': {m: 1, width: '19ch'},
                                          display: 'flex'
                                        }}
                                        noValidate
                                        autoComplete="off"
                                      >
                                        <FormControl>
                                          <TextField
                                            name={`userForm.${i}.name`}
                                            value={item.name}
                                            autoComplete={'false'}
                                            size={'small'}
                                            label={t('name')}
                                            variant="outlined"
                                            onChange={handleChange}
                                            error={userFormTouched.name && Boolean(userFormErrors.name)}
                                            helperText={userFormTouched.name && userFormErrors.name}
                                          />
                                        </FormControl>
                                        <FormControl>
                                          <TextField
                                            name={`userForm.${i}.surname`}
                                            autoComplete={'false'}
                                            value={item.surname}
                                            size={'small'}
                                            label={t('surname')}
                                            variant="outlined"
                                            onChange={handleChange}
                                            error={userFormTouched.surname && Boolean(userFormErrors.surname)}
                                            helperText={userFormTouched.surname && userFormErrors.surname}
                                          />
                                        </FormControl>
                                        <FormControl>
                                          <InputLabel size={'small'} error={userFormTouched.role && Boolean(userFormErrors.role)}>
                                            {t('roleSelect')}
                                          </InputLabel>
                                          <Select
                                            name={`userForm.${i}.role`}
                                            value={item.role}
                                            autoComplete={'false'}
                                            size={'small'}
                                            onChange={handleChange}>
                                            <MenuItem value={''}> {t('role')}</MenuItem>
                                            {roles.map((item, i) => {
                                              return <MenuItem value={item.id} key={i}>
                                                {item.id === Roles.CompanyUser ? t('user') : ''}
                                                {item.id === Roles.CompanyAdmin ? 'Admin' : ''}
                                              </MenuItem>;
                                            })}
                                          </Select>
                                          {
                                            userFormTouched.role && <ErrorMessage name={`userForm.${i}.role`}>{msg => <p style={{
                                              fontSize: '0.75rem',
                                              lineHeight: '1.66',
                                              marginTop: '3px',
                                              color: '#d32f2f',
                                              textAlign: 'left'
                                            }}>
                                              <InfoIcon
                                                style={{
                                                  width: '17px',
                                                  height: '17px',
                                                  marginRight: '5px',
                                                  verticalAlign: 'middle'
                                                }}
                                              />
                                              <span style={{lineHeight: '17px', verticalAlign: 'middle'}}>{msg}</span>
                                            </p>}
                                            </ErrorMessage>
                                          }
                                        </FormControl>
                                        <FormControl style={{width:264}}>
                                          <TextField
                                            name={`userForm.${i}.email`}
                                            value={item.email}
                                            autoComplete={'false'}
                                            size={'small'}
                                            label={t('email')}
                                            type={'text'}
                                            variant="outlined"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={userFormTouched.email && Boolean(userFormErrors.email)}
                                            helperText={userFormTouched.email && userFormErrors.email}/>
                                        </FormControl>

                                        <FormControl>
                                          <TextField
                                            name={`userForm.${i}.password`}
                                            value={item.password}
                                            autoComplete={'false'}
                                            type={'password'}
                                            size={'small'}
                                            label={t('password')}
                                            variant="outlined"
                                            onChange={handleChange}
                                            error={userFormTouched.password && Boolean(userFormErrors.password)}
                                            helperText={userFormTouched.password && userFormErrors.password}/>
                                        </FormControl>
                                        <Box style={{width:'90px'}}>
                                          <IconButton
                                            sx={{width: '40px !important'}}
                                            onClick={() => {
                                              let newUserForm = [...values.userForm];
                                              newUserForm.splice(i, 1);
                                              const newEmailCheckResult = [...emailCheckResults];
                                              newEmailCheckResult.splice(i,1);
                                              setValues({...values, userForm: newUserForm});
                                            }}
                                          >
                                            <DeleteOutlineIcon/>
                                          </IconButton>
                                          {
                                            <IconButton
                                              sx={{width: '40px !important'}}
                                              disabled={approvalEmailLoading ||  emailCheckResults[i].emailExists === false}
                                              onClick={() => {
                                                sendApprovalEmail(item.email);
                                              }}
                                            >
                                              <MailOutlineIcon/>
                                            </IconButton>
                                          }
                                        </Box>
                                      </Box>
                                      <Box style={{marginLeft: 10}}>
                                        {
                                          emailCheckResults[i] && emailCheckResults[i].emailExists === true && <p style={{
                                            fontSize: '0.75rem',
                                            lineHeight: '1.66',
                                            marginTop: '3px',
                                            color: '#d32f2f',
                                            textAlign: 'left'
                                          }}>
                                            <InfoIcon
                                              style={{
                                                width: '17px',
                                                height: '17px',
                                                marginRight: '5px',
                                                verticalAlign: 'middle'
                                              }}
                                            />
                                            <span style={{lineHeight: '17px', verticalAlign: 'middle'}}>
                                              {t('userExist')}
                                            </span>
                                          </p>
                                        }
                                      </Box>
                                    </div>
                                  );
                                })
                              }
                            </Box>
                            <center>
                              <Button
                                startIcon={<AddIcon/>}
                                variant="outlined"
                                sx={{width: 180, mt: 2}}
                                onClick={() => {
                                  let newUserForm = [...values.userForm];
                                  newUserForm.push(userModel);
                                  const newEmailCheckResult = [...emailCheckResults];
                                  newEmailCheckResult.push({email: null, emailExists: false});
                                  setEmailCheckResults(newEmailCheckResult);
                                  setValues({...values, userForm: newUserForm});
                                }}
                              >
                                {t('addRow')}
                              </Button>
                            </center>
                            <FormControlLabel
                              sx={{ml: 2}}
                              disabled
                              control={<Checkbox checked/>}
                              label={t('createAutoPassword')}
                            />
                            <FormControlLabel
                              sx={{ml: 2}}
                              disabled
                              control={<Checkbox checked/>}
                              label={t('changePasswordFirstLogin')}
                            />
                            <FormControlLabel
                              sx={{ml: 2}}
                              control={<Checkbox
                                value={values.sendEmail}
                                onChange={handleChange}
                                name={'sendEmail'}/>}
                              label={t('sendEmail')}
                            />
                          </FormGroup>
                          <Button
                            onClick={() => console.log(formikRef.current.errors)}
                            type={'submit'}
                            variant="contained"
                            sx={{width: 180, mt: 2, float: 'right'}}>
                            {t('next')}
                          </Button>
                        </Box>
                      </form>
                    </Box>
                  </Box>
                )}
              </Formik>
            }

            {
              activeStep === 1 && <Box>
                <Box style={{paddingLeft:20}}>
                  <Box style={{marginBottom:20}}>
                    <FormLabel id="demo-controlled-radio-buttons-group" style={{marginBottom:30}}>{t('licenses')}</FormLabel>
                  </Box>
                  <Box style={{maxHeight:300, overflow:'auto'}}>
                    <FormControl>
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={licence}
                        onChange={onLicenceChange}
                      >
                        {
                          products.map((item,index)=>(<FormControlLabel style={{marginBottom:'15px'}} key={index} value={item.token} control={<Radio />}
                            label={<div>
                              <span style={{display:'block', lineHeight:'15px'}}>{item.productName}</span>
                              <small style={{color:'gray', fontSize:'10px', marginRight:'20px'}}>{t('usedLicense')}: {item.licenceUsed}</small>
                              <small style={{color:'gray', fontSize:'10px'}}>{t('endDate')} :  <Moment format="DD/MM/YYYY hh:mm">
                                {item.endDate}
                              </Moment></small>
                            </div> } />))
                        }
                      </RadioGroup>
                    </FormControl>
                  </Box>
                </Box>
                <div>
                  <Grid item xs={12} md={6} justifyContent={'space-between'}>
                    <Box style={{display: 'flex', justifyContent: 'space-between', marginTop: '40px'}}>
                      <Box width={{md: 150, xs: '45%'}} maxWidth={150}>
                        <Button
                          color={'secondary'} size={'large'} variant={'contained'} onClick={() => {setActiveStep(0);} }
                          sx={{fontWeight: 500, width: '100%', color: 'black'}}>
                          {t('back')}
                        </Button>
                      </Box>
                      <Box width={{md: 150, xs: '45%'}} maxWidth={150}>
                        <Button disabled={licence === null} onClick={()=>onSave()}
                          color={'primary'} type={'submit'} size={'large'} variant={'contained'}
                          sx={{fontWeight: 500, width: '100%', marginLeft: 'auto', marginRight: 0}}>
                          {t('save')}
                        </Button>
                      </Box>
                    </Box>
                  </Grid>
                </div>
              </Box>
            }
          </Box>
        </Box>
      </Modal>

      {/* Success Modal */}
      <Modal
        open={showSuccessModal}
        onClose={() => setShowSuccessModal(false)}
      >
        <Box sx={style}>
          <Box
            display="flex"
            flexDirection={'row'}
            alignContent={'center'}
            justifyContent={'end'}
          >
            <Button onClick={() => setShowSuccessModal(false)}><CloseRoundedIcon/></Button>
          </Box>
          <Typography
            id="modal-modal-title" variant="h6" component="h2"
            sx={{textAlign: 'center', fontWeight: 600, mb: 2, mt: -2}}>
            {response.userForm && response.userForm.length + ' Kullanıcı Eklediniz'}
          </Typography>
          <Box textAlign={'center'} style={{marginTop: '10px', marginBottom: '30px'}}>
            Tüm kullanıcılara geçici şifreleri verildi ve artık hesaplarına giriş yapabilirler.
          </Box>
          <TableContainer component={Paper}>
            <Table
              aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Ad Soyad</TableCell>
                  <TableCell>Kullanıcı Adı</TableCell>
                  <TableCell>Şifre
                    <a
                      style={{textDecoration: 'underline', marginLeft: 5,cursor:'pointer'}}
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? 'Gizle' : 'Göster'}
                    </a>
                  </TableCell>
                  <TableCell>Rol</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {response.userForm && response.userForm.map((row, index) => (
                  <TableRow
                    key={index}
                  >
                    <TableCell component="th" scope="row">{row.name + ' ' + row.surname}</TableCell>
                    <TableCell component="th" scope="row">{row.email}</TableCell>
                    <TableCell component="th" scope="row">{showPassword ? row.password : '********'}</TableCell>
                    <TableCell component="th" scope="row">
                      {row.role === 6 && 'Kullanıcı'}
                      {row.role === 5 && 'Admin'}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box textAlign={'center'} style={{marginTop: '30px', marginBottom: '30px'}}>
            <Button
              onClick={() => {
                setShowSuccessModal(false);
              }}
              variant="contained"
              sx={{width: 180, mt: 2}}
            >
              Kapat
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
}

AddUserModal.propTypes = {
  showAddUserModal: PropTypes.bool,
  setShowAddUserModal: PropTypes.func,
  onSubmitEvent: PropTypes.func
};

export default AddUserModal;
