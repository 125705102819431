import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import SidebarNav from './components/SidebarNav/SidebarNav';
import React, {useEffect} from 'react';
import store from '../../../../redux/store';
import {setSidebarWidth, setMobile} from '../../../../redux/actions';
import {useSelector} from 'react-redux';
const Sidebar = (props) => {
  const {...rest} = props;
  const isMobile = useSelector((state) => state.main.isMobile);
  const sidebarWidth = useSelector((state) => state.main.sidebarWidth);
  const isSidebarOpen = useSelector((state) => state.main.isSidebarOpen);
  const [variant, setVariant] = React.useState('temporary');
  const [screenSize, getDimension] = React.useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight
  });
  const setDimension = () => {
    const mobile = window.innerWidth <= 899;
    store.dispatch(setMobile(mobile));
    setVariant(mobile?'temporary':'permanent');
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight
    });
  };

  useEffect(() => {
    store.dispatch(setSidebarWidth(338));
    setDimension();
  }, []);
  useEffect(() => {
    window.addEventListener('resize', setDimension);

    return (() => {
      window.removeEventListener('resize', setDimension);
    });
  }, [screenSize]);

  return (
    <Drawer
      anchor="left"
      open={isMobile?isSidebarOpen:true}
      variant={variant}
      sx={{
        backgroundColor:'#f3d7d7',
        display: isMobile?{ xs: 'block', md: 'none' }:{ xs: 'none', md: 'block' },
        '& .MuiPaper-root': {
          width: '100%',
          borderColor:'#f4ecec',
          maxWidth: {xs: '100%', sm: sidebarWidth+'px'},
        }
      }}
    >
      <Box
        {...rest}
        sx={{
          height: '100%',
          padding: 1,
          backgroundColor: '#f7f7fa'
        }}
      >
        <SidebarNav/>
      </Box>
    </Drawer>
  );
};

export default Sidebar;
