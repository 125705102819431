import {
  CHANGE_AUTHENTICATION,
  LOG_OUT,
  SET_APPLICATION_INFORMATION_IS_LOADED,
  SET_CALENDAR_DATE,
  SET_CONTAINER_LOADER,
  SET_SELECTED_PACKAGE,
  SET_PACKAGES,
  SET_CURRENT_PRODUCT,
  SET_IS_CURRENCY_DOLLAR,
  SET_IS_LOGIN, SET_JWT,
  SET_PRIVILEGES,
  SET_PRIVILEGE_NAMES,
  SET_USER, SET_SIDEBAR_WIDTH, SET_IS_SIDEBAR_OPEN, SET_IS_MOBILE
} from './types';

export const changeAuthentication = (payload) => {
  return {
    type: CHANGE_AUTHENTICATION,
    payload
  };
};

export const setJwt = (payload) => {
  return {
    type: SET_JWT,
    payload
  };
};

export const setUser = (payload) => {
  return {
    type: SET_USER,
    payload
  };
};

export const setPrivileges = (payload) => {
  return {
    type: SET_PRIVILEGES,
    payload
  };
};

export const setApplicationInformationIsLoaded = (payload) => {
  return {
    type: SET_APPLICATION_INFORMATION_IS_LOADED,
    payload
  };
};

export const setPrivilegeNames = () => {
  return {
    type: SET_PRIVILEGE_NAMES
  };
};

export const setContainerLoader = (payload) => {
  return {
    type: SET_CONTAINER_LOADER,
    payload
  };
};

export const setCurrentProduct = (payload) => {
  return {
    type: SET_CURRENT_PRODUCT,
    payload
  };
};

export const setSelectedPackage = (payload) => {
  return {
    type: SET_SELECTED_PACKAGE,
    payload
  };
};

export const setPackages = (payload) => {
  return {
    type: SET_PACKAGES,
    payload
  };
};

export const setIsLogin = (payload) => {
  return {
    type: SET_IS_LOGIN,
    payload
  };
};

export const setCalendarDate = (payload) => {
  return {
    type: SET_CALENDAR_DATE,
    payload
  };
};

export const setIsCurrencyDollar = (payload) => {
  return {
    type: SET_IS_CURRENCY_DOLLAR,
    payload
  };
};


export const logOut = () => {
  return {
    type: LOG_OUT
  };
};

export const setSidebarWidth = (payload) => {
  return {
    type: SET_SIDEBAR_WIDTH,
    payload
  };
};

export const setSidebarOpen = (payload) => {
  return {
    type: SET_IS_SIDEBAR_OPEN,
    payload
  };
};

export const setMobile = (payload) => {
  return {
    type: SET_IS_MOBILE,
    payload
  };
};