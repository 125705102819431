import MenuIcon from '@mui/icons-material/Menu';
import {SvgIcon} from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';
import React from 'react';
import {useHistory} from 'react-router-dom';
import {ReactComponent as LogoS} from 'svg/logos/LogoBeyaz.svg';
import store from '../../../../redux/store';
import {setSidebarOpen} from '../../../../redux/actions';

const Topbar = () => {
  let history = useHistory();



  const onSidebarOpen = () => {
    store.dispatch(setSidebarOpen(true));
  };


  return (
    <Box
      display={'flex'}
      justifyContent={'space-between'}
      alignItems={'center'}
      width={'100%'}
    >
      <Box display={'flex'} alignItems={'center'}>
        <Box
          display={'flex'}
          alignItems="baseline"
          component="a"
          underline="none"
          style={{
            cursor: 'pointer'
          }}
          onClick={() => history.push('/home')}
          height={{xs: 0, md: 60}}
          width={80}
          paddingTop={{xs: '7px', md: '39px'}}
          marginLeft={{xs: 9, md: '100px'}}
        >
          <SvgIcon
            htmlColor={'white'}
            sx={{transform: {xs: 'scale(7)', md: 'scale(9)'}, height: 'auto'}}
            component={LogoS}
            viewBox="0 0 700 215"/>
        </Box>
      </Box>
      <Box display="flex" alignItems={'center'}>
        <Box
          marginRight={{xs: 1, sm: 2}}
          sx={{display: {xs: 'flex', md: 'none'}, backgroundColor: 'gray', borderRadius: 5}}
        >
          <IconButton onClick={onSidebarOpen} aria-label="Menu">
            <MenuIcon sx={{color: 'white'}}/>
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

Topbar.propTypes = {
  onSidebarOpen: PropTypes.func
};


export default Topbar;

