import React, { Suspense, useEffect } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import Routes from './routers/Routes';
import { CookiesProvider } from 'react-cookie';
import { Provider } from 'react-redux';
import store from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import CircularProgress from '@mui/material/CircularProgress';
import 'moment-timezone';
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'aos/dist/aos.css';
import 'assets/scss/style.scss';

const browserHistory = createBrowserHistory();
let persistor = persistStore(store);

const App = () => {

  useEffect(() => {
    var initLang = localStorage.getItem('i18nextLng');
    if (!initLang) localStorage.setItem('i18nextLng', 'tr');
  }, []);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router history={browserHistory}>
          <CookiesProvider>
            <Suspense fallback={CircularProgress}>
              <Routes />
            </Suspense>
          </CookiesProvider>
        </Router>
      </PersistGate>
    </Provider>
  );
};

export default App;
