import {Button, FormControl, FormGroup, Select} from '@mui/material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import React, {useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import * as Yup from 'yup';
import {CompanyAdminServices} from '../../../../services';
import {Roles} from '../../../../enums';
import {ErrorMessage, Formik} from 'formik';
import InfoIcon from '@mui/icons-material/Info';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '0px solid #000',
  borderRadius: 2,
  boxShadow: 24,
  p: 4
};

function EditUserModal({setShowEditUserModal, showEditUserModal, selected, onSubmitEvent}) {
  const {t} = useTranslation();
  const formikRef = useRef();
  const user = useSelector((state) => state.main.user);
  const [roles, setRoles] = React.useState([]);
  useEffect(() => {
    if (selected && formikRef.current) {
      formikRef.current.setFieldValue('name', selected.name);
      formikRef.current.setFieldValue('surname', selected.surname);
      formikRef.current.setFieldValue('role', selected.roleId);
    }
  }, [formikRef.current]);

  const initialValues = {
    name: '',
    surname: '',
    role: '',
  };

  useEffect(() => {
    roleMap();
  }, []);

  const roleMap = () => {
    const roleList = Object.keys(Roles)
      .map(key => ({id: Roles[key], name: key}));
    setRoles(roleList);
  };

  const onSubmit = async (values) => {
    let sendValues = {...values,
      id:selected.userId};
    CompanyAdminServices.update(sendValues).then((response)=>{
      if (response){
        setShowEditUserModal(false);
        onSubmitEvent();
      }
    });
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .matches(/^'?(?:\p{L}\p{M}*)+(?:['\s](?:\p{L}\p{M}*)+)*'?$/u, t('validName')).max(40)
      .min(2, t('validName'))
      .required(t('requiredField')),
    surname: Yup.string()
      .matches(/^'?(?:\p{L}\p{M}*)+(?:['\s](?:\p{L}\p{M}*)+)*'?$/u, t('validSurname')).max(40)
      .min(2, t('validSurname'))
      .required(t('requiredField')),
    role: Yup.string()
      .required(t('requiredField')),
  });

  return (
    <>
      <Modal
        open={showEditUserModal}
        onClose={() => setShowEditUserModal(false)}
      >
        <Formik
          innerRef={formikRef} initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnChange={true}
          validateOnBlur={true}
          onSubmit={values => {
            onSubmit(values);
          }}>
          {({values, handleSubmit, errors, touched, handleChange}) => (
            <form onSubmit={handleSubmit}>
              <Box sx={style}>
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  sx={{textAlign: 'center', fontWeight: 600, mb: 2, mt: 1}}>
                  {t('editUser')}
                </Typography>
                <FormGroup>
                  <FormControl style={{marginBottom: 15}}>
                    <TextField
                      name={'name'}
                      value={values.name}
                      autoComplete={'false'}
                      size={'small'}
                      id="outlined-basic"
                      label={t('name')}
                      variant="outlined"
                      onChange={handleChange}
                      error={touched.name && Boolean(errors.name)}
                      helperText={touched.name && errors.name}
                    />
                  </FormControl>
                  <FormControl style={{marginBottom: 15}}>
                    <TextField
                      name={'surname'}
                      autoComplete={'false'}
                      value={values.surname}
                      size={'small'}
                      id="outlined-basic"
                      label={t('surname')}
                      variant="outlined"
                      onChange={handleChange}
                      error={touched.surname && Boolean(errors.surname)}
                      helperText={touched.surname && errors.surname}
                    />
                  </FormControl>
                  <FormControl style={{marginBottom: 10}}>
                    <InputLabel size={'small'} error={touched.role && Boolean(errors.role)}>{t('role')}</InputLabel>
                    <Select
                      name={'role'}
                      value={values.role}
                      autoComplete={'false'}
                      size={'small'}
                      disabled={user && selected && user.email === selected.email}
                      onChange={handleChange}>
                      <MenuItem value={''}> {t('role')} </MenuItem>
                      {roles.map((item, i) => {
                        return <MenuItem value={item.id} key={i}>
                          {item.id === Roles.CompanyUser ? t('user') : ''}
                          {item.id === Roles.CompanyAdmin ? t('adminRole') : ''}
                        </MenuItem>;
                      })}
                    </Select>
                    {
                      touched.role && <ErrorMessage name={'role'}>{msg => <p style={{
                        fontSize: '0.75rem',
                        lineHeight: '1.66',
                        marginTop: '3px',
                        color: '#d32f2f',
                        textAlign: 'left'
                      }}>
                        <InfoIcon
                          style={{
                            width: '17px',
                            height: '17px',
                            marginRight: '5px',
                            verticalAlign: 'middle'
                          }}
                        />
                        <span style={{lineHeight: '17px', verticalAlign: 'middle'}}>{msg}</span>
                      </p>}
                      </ErrorMessage>
                    }
                  </FormControl>
                </FormGroup>
                <Button
                  variant="contained"
                  sx={{width: 120, mt: 2, float: 'right'}}
                  type={'submit'}
                >
                  {t('save')}
                </Button>
                <Button
                  onClick={() => setShowEditUserModal(false)}
                  variant="outlined"
                  sx={{width: 90, mt: 2, float: 'right', mr: 2}}>
                  {t('cancel')}
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </Modal>
    </>
  );
}

EditUserModal.propTypes = {
  selected: PropTypes.object,
  showEditUserModal: PropTypes.bool,
  setShowEditUserModal: PropTypes.func,
  onSubmitEvent: PropTypes.func
};

export default EditUserModal;
