import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import Backend from 'i18next-http-backend';

import Turkish from 'locales/tr';
import English from 'locales/en';

const resources = {
  en: {
    translation: English
  },
  tr: {
    translation: Turkish
  },
};

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'tr',
    supportedLngs: ['en', 'tr'],
    debug: false,

    interpolation: {
      escapeValue: false,
    }
  });


export default i18n;
