import CheckIcon from '@mui/icons-material/Check';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import PropTypes from 'prop-types';
import {CompanyAdminServices} from 'services/index';
import {useTranslation} from 'react-i18next';
import {passwordGenerator} from '../../../../utils/loginHelpers';
import {JSEncrypt} from 'jsencrypt';
import {LoadingButton} from '@mui/lab';

const style = {
  position: 'absolute',
  top: {md: '50%', xs: '20%'},
  left: {md: '50%', xs: '32%'},
  transform: {md: 'translate(-50%, -50%)', xs: 'translate(-30%,0%)'},
  maxWidth: 600,
  width: '90%',
  bgcolor: 'background.paper',
  border: '0px solid #000',
  borderRadius: 2,
  boxShadow: 24,
  p: 4
};

function ChangePasswordModalMultiple({selected, setSelected, showChangePasswordModal, setShowChangePasswordModal}) {
  const {t} = useTranslation();
  const [showSuccessModal, setShowSuccessModal] = React.useState(false);
  const [isMailSend, setIsMailSend] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [resultUsers, setResultUsers] = React.useState([]);
  const onSubmit = () => {
    var encrypt = new JSEncrypt();
    encrypt.setPublicKey('MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDGy8btrbnSNPz7vWKfQXKxKXzg28ZD8jCAd7gGYfUIFqKqUcogHWt5gyGvTgEhwBwBP1kYrVnBlhB2nuWHLYpJDI6buBoqKrHtrcdgXsKumSP0OKpn0nbYxknOvNYVjUUR6plMboUBaWX1oKoR6pNzTEHSal4bIU7XMwppkR3KNQIDAQAB');
    const emailUsers = selected.map((item)=>{
      const password = passwordGenerator(8);
      return {email:item.email,  password};
    });
    setIsLoading(true);
    let sendValues = {emailUsers:emailUsers.map((item)=>{return {email:item.email,password:encrypt.encrypt(item.password)};}), isMailSend};
    CompanyAdminServices.passwordResetUsers(sendValues).then((response)=>{
      setIsLoading(false);
      if (response){
        setShowChangePasswordModal(false);
        setShowSuccessModal(true);
        setResultUsers(emailUsers);
        setSelected([]);
      }
    });
  };


  return (
    <div>
      <Modal
        open={showChangePasswordModal}
        onClose={() => setShowChangePasswordModal(false)}
      >
        <Box sx={style}>
          <Box
            display="flex"
            flexDirection={'row'}
            alignContent={'center'}
            justifyContent={'end'}
          >
            <Button onClick={() => setShowChangePasswordModal(false)}><CloseRoundedIcon/></Button>
          </Box>
          <Typography
            id="modal-modal-title" variant="h6" component="h2"
            sx={{textAlign: 'center', fontWeight: 600, mb: 2, mt: -2}}>
            {t('passwordReset')}
          </Typography>
          <FormGroup>
            <FormControlLabel
              disabled
              control={<Checkbox checked/>}
              label={t('createAutoPassword')}
            />
            <FormControlLabel
              disabled control={<Checkbox checked/>}
              label={t('changePasswordFirstLogin')}
            />
            <FormControlLabel
              control={<Checkbox  checked={isMailSend} onChange={()=>setIsMailSend(!isMailSend)}/>}
              label={t('sendEmail')}
            />
          </FormGroup>
          <center>
            <LoadingButton
              loading={isLoading}
              onClick={() => {onSubmit();}}
              color={'primary'}
              sx={{width: 180, mt: 2, fontWeight: 500}}
              variant={'contained'}
              type={'submit'}
            >
              {t('save')}
            </LoadingButton>
          </center>
        </Box>
      </Modal>

      {/* Success Modal */}
      <Modal
        open={showSuccessModal}
        onClose={() => setShowSuccessModal(false)}
      >
        <Box sx={style}>
          <Box
            display="flex"
            flexDirection={'row'}
            alignContent={'center'}
            justifyContent={'end'}
          >
            <Button onClick={() => setShowSuccessModal(false)}><CloseRoundedIcon/></Button>
          </Box>
          <Typography
            id="modal-modal-title" variant="h6" component="h2"
            sx={{textAlign: 'center', fontWeight: 600, mb: 2, mt: -2}}>
            {t('passwordResetSuccess')}
          </Typography>
          <center>
            <CheckIcon sx={{height: 50, width: 50, color: 'green'}}/>
          </center>
          <center>
            {t('changePasswordIsSuccess')}
          </center>
          <TableContainer component={Paper}>
            <Table
              aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>{t('userName')}</TableCell>
                  <TableCell>{t('password')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {resultUsers.map((row, index) => (
                  <TableRow
                    key={index}
                  >
                    <TableCell component="th" scope="row">{row.email}</TableCell>
                    <TableCell align="left">{row.password}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <center>
            <Button
              onClick={() => setShowSuccessModal(false)}
              variant="contained"
              sx={{width: 180, mt: 2}}
            >
              {t('close')}
            </Button>
          </center>
        </Box>
      </Modal>
    </div>
  );
}

ChangePasswordModalMultiple.propTypes = {
  selected: PropTypes.array,
  setSelected: PropTypes.func,
  showChangePasswordModal: PropTypes.bool,
  setShowChangePasswordModal: PropTypes.func
};

export default ChangePasswordModalMultiple;
