import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Form, Formik } from 'formik';
import React from 'react';
import CreditCards2 from 'svg/illustrations/CreditCards2';
import Swal from 'sweetalert2';
import * as yup from 'yup';
import InvoiceForm from '../components/InvoiceForm';
import 'react-phone-input-2/lib/style.css';
import valid from 'card-validator';
import InputLabel from '@mui/material/InputLabel';

const validationSchema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .required('Bu alan zorunludur.')
    .matches(/^[a-zA-Z]+ [a-zA-Z]+$/, 'Geçerli bir ad soyad giriniz'),
  number: yup
    .number('Kart numarası rakamlardan oluşmalıdır. ')
    .required('Bu alan zorunludur.').test(
      'test-number',
      'Geçerli bir kart numarası giriniz.',
      value => valid.number(value).isValid,
    ),
  date: yup
    .string()
    .required('Bu alan zorunludur.')
    .matches(/^(0\d|1[0-2])\/\d{2}$/, 'Geçerli bir son kullanma tarihi giriniz'),
  cvc: yup
    .string()
    .required('Bu alan zorunludur.')
    .matches(/^[0-9]{3,4}$/, 'Geçerli bir güvenlik kodu (CVC) giriniz.'),
});

const steps = ['Fatura Bilgileriniz', 'Ödeme Bilgileri'];
const Enterprise = () => {
  const [activeStep, setActiveStep] = React.useState(0);

  const initialValues = {
    name: '',
    number: '',
    date: '',
    cvc: '',
  };

  const onSubmit = (values) => {
    console.log('values', values);
    let timerInterval;
    Swal.fire({
      width: 400,
      icon: 'success',
      title: 'Başarılı',
      timer: 4000,
      timerProgressBar: true,
      showCloseButton: false,
      showConfirmButton: true,
      showDenyButton: false,
      confirmButtonText: 'Kullanmaya Başla',
      text: 'Ödeme bilgileri başarılı bir şekilde kaydedilmiştir.',
      willClose: () => {
        clearInterval(timerInterval);
      },
    });
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const onInvoiceFormSubmited = (values) => {
    console.log('values', values);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  return (
    <Grid
      item
      xs={12}
      sm={8}
      md={9}
      xl={10} paddingTop={window.innerWidth > 600 ? 10 : 3}
      paddingLeft={window.innerWidth > 600 ? 15 : 3}
      paddingRight={window.innerWidth > 600 ? 15 : 3}>
      <Box sx={{ width: '100%', marginTop: 5 }}>
        <Grid container p={8}>

          {/* Sol form */}
          <Grid item xl={6} md={6} sm={12}>
            <Stepper activeStep={activeStep}>
              {steps.map((label) => {
                const stepProps = {};
                const labelProps = {};
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            {activeStep === 0 ? (
              <InvoiceForm type={'enterprise'} onSubmited={onInvoiceFormSubmited} />
            ) : (
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                validateOnChange={true}
                validateOnBlur={true}
                onSubmit={values => {
                  onSubmit(values);
                }}
              >
                {({ errors, touched, values, handleChange }) => (
                  <Form style={{ marginTop: 30 }}>
                    <Grid
                      container
                      spacing={3}
                      direction="column"
                      alignItems={{ xs: 'stretched', sm: 'stretched' }}
                      justifyContent="center">

                      <CreditCards2 width={'100%'} height={250} />

                      {/* Kart üzerindeki isim */}
                      <Grid item xs={12} md={6}>
                        <InputLabel sx={{ fontWeight: 600 }}>Kart Üzerindeki Ad Soyad*</InputLabel>
                        <TextField
                          autoComplete="false"
                          variant="outlined"
                          fullWidth
                          name={'name'}
                          value={values.name}
                          onChange={handleChange}
                          error={touched.name && Boolean(errors.name)}
                          helperText={touched.name && errors.name}
                        />
                      </Grid>

                      {/* Kart Numarası*/}
                      <Grid item xs={12} md={6}>
                        <InputLabel sx={{ fontWeight: 600 }}>Kart Numarası*</InputLabel>
                        <TextField
                          autoComplete="false"
                          variant="outlined"
                          name={'number'}
                          value={values.number}
                          fullWidth
                          onChange={handleChange}
                          error={touched.number && Boolean(errors.number)}
                          helperText={touched.number && errors.number}
                          onInput={(e) => {
                            const value = e.target.value;
                            const length = value.length;
                            if (length > 16) {
                              e.target.value = value.toString().slice(0, 16);
                            }
                            const reg = new RegExp('^[0-9]*$');
                            if ((!reg.test(value))) {
                              e.target.value = value.toString().slice(0, value.length - 1);
                            }
                          }}
                        />
                      </Grid>

                      <Grid container spacing={2} ml={1} mt={1}>
                        <Grid item md={6}>
                          <InputLabel sx={{ fontWeight: 600 }}>Son Kullanma Tarihi*</InputLabel>
                          <TextField
                            autoComplete="false"
                            variant="outlined"
                            name={'date'}
                            placeholder={'Ay / Yıl'}
                            value={values.date}
                            fullWidth
                            onChange={handleChange}
                            error={touched.date && Boolean(errors.date)}
                            helperText={touched.date && errors.date}
                            onInput={(e) => {
                              const value = e.target.value;
                              const lastChar = value.charAt(value.length - 1);
                              const length = value.length;
                              if (length > 5) {
                                e.target.value = value.toString().slice(0, 5);
                              } else if (e.target.value.length === 2)
                                e.target.value = e.target.value + '/';
                              else if (length === 3 && value.charAt(2) === '/')
                                e.target.value = value.replace('/', '');
                              const reg = new RegExp('^[0-9/]*$');
                              if ((!reg.test(lastChar))) {
                                e.target.value = value.toString().slice(0, value.length - 1);
                              }
                            }}
                          />
                        </Grid>
                        <Grid item md={4}>
                          <InputLabel sx={{ fontWeight: 600 }}>CVC*</InputLabel>
                          <TextField
                            autoComplete="false"
                            variant="outlined"
                            name={'cvc'}
                            type={'number'}
                            fullWidth
                            value={values.cvc}
                            onChange={handleChange}
                            error={touched.cvc && Boolean(errors.cvc)}
                            helperText={touched.cvc && errors.cvc}
                          />
                        </Grid>
                      </Grid>

                      {/* Sözleşme onayları */}
                      <Grid item container xs={12} md={6} justifyContent={'center'}>
                        <Button
                          color={'secondary'}
                          size={'large'}
                          variant={'contained'}
                          onClick={() => handleBack()}
                          sx={{ fontWeight: 500, width: 150, color: 'black' }}>
                          Geri
                        </Button>
                        <Button
                          color={'primary'}
                          type={'submit'}
                          size={'large'}
                          variant={'contained'}
                          sx={{ fontWeight: 500, width: 150, marginLeft: 'auto', marginRight: -3 }}>
                          Kaydet
                        </Button>
                      </Grid>
                    </Grid>
                    <br />
                    <br />
                    <br />
                    <br />
                  </Form>
                )}
              </Formik>
            )}
          </Grid>

          {/* Sağ panel */}
          <Grid item xl={6} md={6} sm={12} xs={12}>
            <div style={{ marginLeft: 40, marginTop: 60 }}>
              <Box>
                <Typography sx={{ fontWeight: 600, color: '#000', fontSize: 23, marginLeft: 5 }}>Telepati Ücretsiz
                  Kullanım</Typography>
              </Box>
              <ul style={{ color: '#000' }}>
                <li>100+ kullanıcı ekleyebilirsiniz.</li>
                <br />
                <li>Aboneliğinizi 15 gün içerisinde iptal edebilirsiniz.</li>
                <br />
                <li>Sınırsız sürede toplantı yapabilirsiniz</li>
                <br />
              </ul>
              <Box>
                <Typography sx={{ fontWeight: 600, color: '#000', fontSize: 23, marginLeft: 5 }}>Ürün
                  Özellikleri</Typography>
              </Box>
              <ul style={{ color: '#000' }}>
                <li>500 Katılımcıya kadar toplantı oluşturabilirsiniz.</li>
                <br />
                <li>10 Saate kadar grup toplantısı yapabilirsiniz.</li>
                <br />
                <li>Ekran paylaşımı yapabilirsiniz.</li>
                <br />
                <li>Toplantı notu oluşturup çıktı alabilirsiniz.</li>
                <br />
                <li>Kullanıcı listesini indirebilirsiniz.</li>
              </ul>
            </div>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};

export default Enterprise;
