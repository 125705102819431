import {Ajax, ArrayHelper} from '../utils/index';

const BASE_URL = '/CompanyAdmin';
export default {
  get (params) {
    ArrayHelper.clean(params);
    return Ajax.postPromise(BASE_URL+'/get-all',params);
  },
  save (data) {
    return Ajax.postPromise(BASE_URL+'/save', data);
  },
  update (data) {
    return Ajax.putPromise(BASE_URL+'/update', data);
  },
  activate (data) {
    return  Ajax.putPromise(BASE_URL+'/activate', {userIdList:data});
  },
  deactive (data) {
    return Ajax.putPromise(BASE_URL+'/deactivate', {userIdList:data});
  },
  checkEmail (emailList) {
    return Ajax.postPromise(BASE_URL+'/check-email', {emailList});
  },
  sendCompanyApprovalEmail (data) {
    return Ajax.postPromise(BASE_URL+'/send-company-approval-email', data);
  },
  passwordResetUsers (data) {
    return Ajax.postPromise(BASE_URL+'/password-reset-users', data);
  },
  getAllProducts (params) {
    ArrayHelper.clean(params);
    return Ajax.getPromise(BASE_URL+'/get-all-products',params);
  },
  getAllProductPaymentDetail (params) {
    ArrayHelper.clean(params);
    return Ajax.getPromise(BASE_URL+'/get-product-payment-detail',params);
  },
};
