import {LinearProgress} from '@mui/material';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import TableResponsive from '../../../../common/TableResponsive';
import {CompanyAdminServices} from 'services/index';
import IconButton from '@mui/material/IconButton';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DetailModal from './DetailModal';
import Moment from 'react-moment';
import {useTranslation} from 'react-i18next';

export default function DataTable() {
  const {t} = useTranslation();
  const [selected, setSelected] = React.useState({});
  const [showDetailModal, setShowDetailModal] = React.useState(false);
  const user = useSelector((state) => state.main.user);
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    getList();
  }, [location.pathname]);

  const getList = () => {
    setLoading(true);
    const filter = {
      userId: user.id,
    };
    const promise = CompanyAdminServices.getAllProducts(filter);
    promise.then(async (response)=> {
      let data = [...response.data.result];
      setData(data);
      setLoading(false);
    });
  };

  const columns = [
    {
      id: 'productName',
      disablePadding: true,
      label: t('productName')
    },
    {
      id: 'licenceUsed',
      disablePadding: false,
      label: t('assignedLicenseCount')
    },
    {
      id: 'totalLicence',
      disablePadding: false,
      label: t('purchasedLicenseCount')
    },
    {
      id: 'packageStatus',
      disablePadding: false,
      label: t('status')
    },
    {
      id: 'subscription',
      disablePadding: false,
      label: t('billingType')
    },
    {
      id: 'endDate',
      disablePadding: false,
      label: t('endDate')
    },
    {
      id: 'actions',
      disablePadding: false,
      label: t('actions')
    },
  ];

  function EnhancedTableHead() {
    return (
      <TableHead sx={{backgroundColor: '#ECEBEE'}}>
        <TableRow>
          {columns.map((colItem) => (
            <TableCell
              key={colItem.id}
              align={'center'}
              padding={colItem.disablePadding ? 'none' : 'normal'}>
              {colItem.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  return (
    <Box sx={{width: '100%'}}>
      <Paper sx={{width: '100%', mb: 2}}>
        <TableResponsive style={{minWidth: '900px'}}>
          <TableContainer style={{position: 'relative'}}>
            <LinearProgress color="primary" style={{
              opacity: loading ? 1 : 0,
              transition: 'all .3s linear',
              position: 'absolute',
              left: 0,
              right: 0,
              top: 0
            }}/>
            <Table
              aria-labelledby="tableTitle">
              <EnhancedTableHead
                rowCount={data ? data.length : 0}
              />
              <TableBody>
                {data && data.map((row, index) => {
                  return (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={index}
                    >
                      {columns.map((colItem) => (
                        <>

                          {
                            colItem.id === 'productName' && <TableCell align="center">{row.productName}</TableCell>
                          }
                          {
                            colItem.id === 'licenceUsed' && <TableCell align="center">{row.licenceUsed}</TableCell>
                          }
                          {
                            colItem.id === 'totalLicence' && <TableCell align="center">{row.totalLicence}</TableCell>
                          }
                          {
                            colItem.id === 'packageStatus' && <TableCell align="center">
                              {row.packageStatus === 0 && t('cancelled')}
                              {row.packageStatus === 1 && t('continues')}
                              {row.packageStatus === 2 && t('complated')}
                            </TableCell>
                          }
                          {
                            colItem.id === 'subscription' && <TableCell align="center"> 
                              {row.subscription === 1 && t('monthly')}
                              {row.subscription === 2 && t('yearly')}
                            </TableCell>
                          }
                          {
                            colItem.id === 'endDate' && <TableCell align="center">
                              <Moment format="DD/MM/YYYY hh:mm">
                                {row.endDate}
                              </Moment>
                            </TableCell>
                          }
                          {
                            colItem.id === 'email' && <TableCell align="center">{row.email}</TableCell>
                          }
                          {
                            colItem.id === 'actions' && <TableCell align="center">
                              <IconButton
                                disabled={index === 1}
                                onClick={() => {
                                  setSelected(row);
                                  setShowDetailModal(index !== 1);
                                }}
                              >
                                <MoreHorizIcon/>
                              </IconButton>
                            </TableCell>
                          }
                        </>
                      ))}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </TableResponsive>
      </Paper>

      <DetailModal
        selected={selected}
        setShowDetailModal={setShowDetailModal}
        showDetailModal={showDetailModal}
      />
    </Box>
  );
}
