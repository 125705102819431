import React from 'react';
import {Route, Switch} from 'react-router-dom';
import ChangePassword from 'views/authPages/ChangePassword/ChangePassword';
import WithLayout from 'WithLayout';
import {BodyMain as BodyLayout} from '../layouts/index';
import {Main as MainLayout} from '../layouts/index';
import LoginPage from '../views/authPages/LoginSimple/LoginPage';
import AuthRoute from './authRoute';
import TrialStart from '../views/supportingPages/Pricing/TrialStart/TrialStart';
import Pricing from '../views/supportingPages/Pricing/Pricing';
import BuyPackage from '../views/supportingPages/Pricing/BuyPackage/BuyPackage';
import Enterprise from '../views/supportingPages/Pricing/Enterprise/Enterprise';
import Products from '../views/supportingPages/Products/Products';
import Home from '../views/landingPages/Home/Home';
import ContactUs from '../views/supportingPages/ContactUs/ContactUs';
import Users from '../views/supportingPages/Users/Users';

const Routes = () => {
  return (
    <Switch>
      <Route
        exact
        path="/"
        render={(matchProps) => (
          <WithLayout
            {...matchProps}
            component={LoginPage}
            layout={BodyLayout}
          />
        )}
      />
      <AuthRoute
        exact
        path="/page-login-simple"
        type="guest"
        render={(matchProps) => (
          <WithLayout
            {...matchProps}
            component={LoginPage}
            layout={BodyLayout}
          />
        )}>
      </AuthRoute>
      <AuthRoute
        path="/account/password-verification"
        render={(matchProps) => (
          <WithLayout
            {...matchProps}
            component={ChangePassword}
            layout={BodyLayout}
          />
        )}
      />
      <AuthRoute
        exact
        path="/contact-us"
        render={(matchProps) => (
          <WithLayout
            {...matchProps}
            component={ContactUs}
            layout={MainLayout}
          />
        )}
      />
      <AuthRoute
        type="private"
        path="/page-pricing/pricing"
        render={(matchProps) => (
          <WithLayout
            {...matchProps}
            component={Pricing}
            layout={MainLayout}
          />
        )}
      />
      <AuthRoute
        type="private"
        path="/page-pricing/products"
        render={(matchProps) => (
          <WithLayout
            {...matchProps}
            component={Products}
            layout={MainLayout}
          />
        )}
      />
      <AuthRoute
        type="private"
        path="/page-pricing/trial-start"
        render={(matchProps) => (
          <WithLayout
            {...matchProps}
            component={TrialStart}
            layout={MainLayout}
          />
        )}
      />
      <AuthRoute
        type="private"
        path="/page-pricing/buypackage"
        render={(matchProps) => (
          <WithLayout
            {...matchProps}
            component={BuyPackage}
            layout={MainLayout}
          />
        )}
      />
      <AuthRoute
        type="private"
        path="/page-pricing/enterprise"
        render={(matchProps) => (
          <WithLayout
            {...matchProps}
            component={Enterprise}
            layout={MainLayout}
          />
        )}
      />
      <AuthRoute
        type="private"
        path="/users"
        render={(matchProps) => (
          <WithLayout
            {...matchProps}
            component={Users}
            layout={MainLayout}
          />
        )}
      />
      <AuthRoute
        type="private"
        path="/users"
        render={(matchProps) => (
          <WithLayout
            {...matchProps}
            component={Users}
            layout={MainLayout}
          />
        )}
      />
      <AuthRoute
        type="private"
        exact
        path="/home"
        render={(matchProps) => (
          <WithLayout
            {...matchProps}
            component={Home}
            layout={MainLayout}
          />
        )}
      />
    </Switch>
  );
};

export default Routes;
