import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';

const CurrencySymbol = () => {
  const isDollar = useSelector((state) => state.main.isCurrencyDollar);

  useEffect(() => {
    console.log('currency',isDollar);
  }, [isDollar]);

  return (
    <span>
      {isDollar?'$':'₺'}
    </span>
  );
};

export default CurrencySymbol;
