import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import {Checkbox, FormControlLabel, Modal} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import loginTypeEnum from 'enums/loginTypeEnum';
import {useFormik} from 'formik';
import React, {useEffect, useRef, useState} from 'react';
import {useCookies} from 'react-cookie';
import {useHistory} from 'react-router-dom/cjs/react-router-dom.min';
import {AccountServices, UserServices} from 'services/index';
import {handleAfterLoginEnumWorks} from 'utils/loginHelpers';
import ForgotPasswordSimple from 'views/authPages/ForgotPasswordSimple/ForgotPasswordSimple';
import * as yup from 'yup';
import {JSEncrypt} from 'jsencrypt';
import ReCAPTCHA from 'react-google-recaptcha';
import InfoIcon from '@mui/icons-material/Info';

import {
  changeAuthentication,
  setApplicationInformationIsLoaded,
  setPrivilegeNames,
  setPrivileges,
  setUser
} from '../../../../redux/actions';
import store from '../../../../redux/store';
import NewPasswordModal from './NewPasswordModal';
import {LoadingButton} from '@mui/lab';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';


Yup.addMethod(Yup.string, 'emailSyntaxCheck', function (errorMessage) {
  return this.test('test-card-length', errorMessage, function (value) {
    const {path, createError} = this;
    const re = /^[a-zA-Z0-9.'_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    return re.test(String(value).toLowerCase()) ? true : createError({path, message: errorMessage});
  });
});



const LoginForm = () => {
  const {t, i18n} = useTranslation();
  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookie] = useCookies(['token', 'public-token']);
  const [show, setShow] = useState(false);
  const [showNewPasswordModal, setShowNewPasswordModal] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const [reCaptchaLoad, setReCaptchaLoad] = useState(false);
  const recaptchaRef = useRef();

  const initialValues = {
    email: '',
    password: '',
    recaptcha:''
  };

  const validationSchema = yup.object({
    recaptcha: yup.string().required(t('validRecaptcha')).nullable(true),
    email: Yup
      .string()
      .trim()
      .email(t('validEmail'))
      .matches(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/, 'Geçerli bir E-posta Giriniz')
      .emailSyntaxCheck(t('validEmail'))
      .required(t('requiredField')),
    password: Yup
      .string()
      .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/, t('validPasswordRegex'))
      .required(t('validPassword'))
      .min(8, t('validPasswordMin'))
  });

  useEffect(() => {
    formik.resetForm();
    setTimeout(() => {
      setReCaptchaLoad(true);
    }, 1500);
    return () => {
      formik.resetForm();
    };
  }, []);

  useEffect(() => {
    setReCaptchaLoad(false);
    setTimeout(() => {
      setReCaptchaLoad(true);
    }, 1500);
  }, [i18n.language]);



  const onSubmit = (values) => {
    let sendValues = {...values};
    sendValues.password = values.password;
    sendValues.loginType = loginTypeEnum.StandAlone;
    setIsLoading(true);
    signIn(sendValues);
    return;
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit
  });

  const signIn = (values) => {
    let data = {...values};
    let mustChangePassword = null;
    var encrypt = new JSEncrypt();
    encrypt.setPublicKey('MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDGy8btrbnSNPz7vWKfQXKxKXzg28ZD8jCAd7gGYfUIFqKqUcogHWt5gyGvTgEhwBwBP1kYrVnBlhB2nuWHLYpJDI6buBoqKrHtrcdgXsKumSP0OKpn0nbYxknOvNYVjUUR6plMboUBaWX1oKoR6pNzTEHSal4bIU7XMwppkR3KNQIDAQAB');
    delete data.recaptcha;
    data.password = encrypt.encrypt(data.password);
    const promise = AccountServices.login(data);
    promise
      .then((response) => {
        if (response) {
          AccountServices.publicLogin(data).then((publicResponse) => {
            setCookie('public-token', publicResponse.data.token, {path: '/'});
          });
          mustChangePassword = response.data.mustChangePasswordNextLogon;
          setCookie('token', response.data.token, {path: '/'});
          handleAfterLoginStoreWorks(mustChangePassword);
          handleAfterLoginEnumWorks();
        }
        setIsLoading(false);
      })
      .catch(() => {
        data.password = '';
        setIsLoading(false);
      });
  };

  const handleAfterLoginStoreWorks = (mustChangePassword) => {
    let promise = UserServices.getUserInformation();
    promise
      .then((response) => {
        store.dispatch(setPrivileges(response.data.privileges));
        store.dispatch(changeAuthentication(!!response.data.user));
        store.dispatch(setUser(response.data.user));
        store.dispatch(setApplicationInformationIsLoaded(true));
        store.dispatch(setPrivilegeNames());
      })
      .then(() => {
        if (mustChangePassword == true) {
          setShowNewPasswordModal(true);
        } else {
          history.push('/home');
        }
      });
  };


  return (
    <Box>
      <Box marginBottom={8} textAlign={'center'}>
        <Typography
          variant="h4"
          color="text.tertiary"
          sx={{
            fontWeight: 700
          }}
        >
          {t('adminPanel')}
        </Typography>
      </Box>
      <Box marginBottom={2} textAlign={'center'}>
        <Typography
          color="text.tertiary"
          sx={{
            fontWeight: 600,
            fontSize: 28
          }}
        >
          {t('accountSignIn')}
        </Typography>
      </Box>

      <form onSubmit={formik.handleSubmit}>
        <Grid
          container
          spacing={2}
          direction="column"
          alignItems={'center'}
          justifyContent="center"

        >
          <Grid
            item
            xs={12}
            width={'85%'}
            md={6}
          >
            <TextField
              label={t('registerEmail')}
              variant="outlined"
              name={'email'}
              fullWidth={true}
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Grid>

          <Grid
            item
            xs={12}
            md={6}
            width={'85%'}
          >
            <TextField
              label={t('registerPassword')}
              variant="outlined"
              name={'password'}
              type={'password'}
              fullWidth
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />

          </Grid>
          <Grid item>
            <Box
              display="flex"
              flexDirection={{xs: 'row', sm: 'row'}}
              alignItems={{xs: 'center', sm: 'center'}}
              justifyContent={'space-between'}
              width={'100%'}
              marginY={1}
            >
              <FormControlLabel
                control={<Checkbox defaultunchecked="true"/>}
                label={t('rememberMe')}
                sx={{
                  '& span': {
                    fontSize: '0.875em'
                  }
                }}
              />
              <Typography variant={'subtitle2'}>
                <Link
                  component={Button}
                  color={'primary'}
                  onClick={handleShow}
                  underline={'none'}
                >
                  {t('accountForgotPassword')}
                </Link>
                <Modal
                  open={show}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box
                    backgroundColor={'white'}
                    paddingBottom={1}
                    maxWidth={600}
                    sx={{
                      position: 'absolute',
                      left: {xs: '10%', md: '50%'},
                      top: {xs: '25%', md: '50%'},
                      transform: {xs: 'translate(-5%, -25%)', md: 'translate(-50%, -50%)'}
                    }}>
                    <Box
                      display="flex"
                      flexDirection={'row'}
                      alignContent={'center'}
                      justifyContent={'end'}
                    >
                      <Button onClick={handleClose}><CloseRoundedIcon/></Button>
                    </Box>
                    <ForgotPasswordSimple/>
                  </Box>
                </Modal>
              </Typography>
            </Box>
          </Grid>
          <Grid item container xs={12} md={6} justifyContent={'center'}>
            {
              reCaptchaLoad && <>
                <ReCAPTCHA
                  ref={recaptchaRef}
                  hl={i18n.language}
                  sitekey={process.env.REACT_APP_SITE_KEY}
                  onChange={(value) => {
                    formik.setFieldValue('recaptcha', value);
                    formik.setSubmitting(false);
                  }}
                  size="normal"
                />
                {
                  (formik && formik.touched && formik.touched.recaptcha && formik.errors.recaptcha) ?  <p style={{
                    fontSize: '0.75rem',
                    lineHeight: '1.66',
                    marginTop: '3px',
                    color: '#d32f2f'
                  }}>
                    <InfoIcon
                      style={{
                        width: '17px',
                        height: '17px',
                        marginRight: '5px',
                        verticalAlign: 'middle'
                      }}/>
                    <span style={{ lineHeight: '17px', verticalAlign: 'middle' }}>{formik.errors.recaptcha}</span>
                  </p> : null
                }
              </>
            }
          </Grid>
          <Grid item container xs={12} md={6} justifyContent={'center'}>
            <Box
              display="flex"
              flexDirection={{xs: 'column', sm: 'column'}}
              alignContent={'space-between'}
              justifyContent={'space-between'}
            >

              <LoadingButton
                loading={isLoading}
                color={'primary'}
                size={'large'}
                variant={'contained'}
                type={'submit'}
                sx={{fontWeight: 500}}
              >
                {t('accountSignIn')}
              </LoadingButton>
            </Box>
          </Grid>
        </Grid>
      </form>

      {showNewPasswordModal &&
        <NewPasswordModal
          showNewPasswordModal={showNewPasswordModal}
          setShowNewPasswordModal={setShowNewPasswordModal}
        />
      }
    </Box>
  );
};

export default LoginForm;
