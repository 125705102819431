import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';

const TableResponsive = ({ children, ...rest }) => (
  <Box overflow={'auto'}>
    <Box minWidth={'900px'} {...rest}>
      {children}
    </Box>
  </Box>
);

TableResponsive.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TableResponsive;
