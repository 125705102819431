import {Button, Grid} from '@mui/material';
import PropTypes from 'prop-types';
import PricingCard from './PricingCard';
import {useSelector} from 'react-redux';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import React, {useEffect} from 'react';


const PricingTable = (props) => {
  const {licenseType} = props;
  const [selectedPage, setSelectedPage] = React.useState(1);
  const [isMobile, setIsMobile] = React.useState(false);
  const [screenSize, getDimension] = React.useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight
  });
  const setDimension = () => {
    if (screenSize.dynamicWidth > 1024) {
      setIsMobile(false);
    } else {
      setIsMobile(true);
    }

    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight
    });
  };
  useEffect(() => {
    setDimension();
  }, []);
  useEffect(() => {
    window.addEventListener('resize', setDimension);

    return (() => {
      window.removeEventListener('resize', setDimension);
    });
  }, [screenSize]);
  const packages = useSelector((state) => {
    return state.main.packages;
  });
  
  return (
    <>
      <Grid container  direction={'row'} mt={5} paddingX={0}>
        {
          isMobile &&  <Grid item md={12} style={{justifyContent:'center', width:'100%', marginTop:'20px'}}><CustomPagination
            selectedPage={selectedPage}
            setSelectedPage={setSelectedPage}
          /></Grid>
        }
        {
          !isMobile ? packages && packages.map((item, i) => (
            <Grid key={i} md={4} paddingX={2} item>
              <PricingCard
                licenseType={licenseType}
                item={item}/>
            </Grid>
          )) :
            <Grid md={12} paddingX={2} style={{width:'100%'}} item><PricingCard
              licenseType={licenseType}
              item={packages[selectedPage - 1]}
            /></Grid>
        }
      </Grid>
    </>
  );

};

function CustomPagination({selectedPage, setSelectedPage}) {
  return (
    <Grid container direction="row" justifyContent="center">
      <Grid item>
        <Button
          onClick={() => setSelectedPage(selectedPage !== 1 ? selectedPage - 1 : selectedPage)}
          sx={{borderRadius: 3}}
          variant="contained"
        >
          <KeyboardArrowLeftIcon/>
        </Button>
      </Grid>
      <Grid item mt={1.5} mb={4}>
        <Grid container direction="row">
          <Grid
            item
            sx={{
              mr: 0,
              ml: 4,
              height: 13,
              width: 13,
              border: '1px solid gray',
              borderRadius: 7,
              backgroundColor: selectedPage === 1 ? '#331688' : '#fff'
            }}/>
          <Grid
            item
            sx={{
              mr: 1,
              ml: 1,
              height: 13,
              width: 13,
              border: '1px solid gray',
              borderRadius: 7,
              backgroundColor: selectedPage === 2 ? '#331688' : '#fff'
            }}/>
          <Grid
            item
            sx={{
              ml: 0,
              mr: 4,
              height: 13,
              width: 13,
              border: '1px solid gray',
              borderRadius: 7,
              backgroundColor: selectedPage === 3 ? '#331688' : '#fff'
            }}/>
        </Grid>

      </Grid>
      <Grid item>
        <Button
          onClick={() => setSelectedPage(selectedPage !== 3 ? selectedPage + 1 : selectedPage)}
          sx={{borderRadius: 3}}
          variant="contained"
        >
          <KeyboardArrowRightIcon/>
        </Button>
      </Grid>
    </Grid>
  );
}

CustomPagination.propTypes = {
  selectedPage: PropTypes.number,
  setSelectedPage: PropTypes.func
};

PricingTable.propTypes = {
  licenseType: PropTypes.string,
};

export default PricingTable;
